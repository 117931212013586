@import '../themes/default/colours.less';
@import '../themes/default/config.less';

// Molecule: "Footer Social Media Links"
//

//== Variables
//

//== Mixins
//

//== Block
//

.footer-logos {
    margin-bottom: 1em;
}


//== Elements
//

.footer-logos__link {
    display: table-cell;
    padding: 0 2em 1em 0;

    vertical-align: middle;
}

//== Modifiers
//
