//
// Form Controls
// =========================================

//== Mixins
//

//== Block

@media (min-width: @screen-md-min) {
    .form-area {
        float: left;
        width: 66%;
    }
}


//

//== Elements

.form-group {
    .clearfix();
    margin-bottom: 3em;
    display: block;

    label {
        float: left;
        width: 49%;
    }
}

.form-group__item {
    float: right;
    width: 49%;
}

//

//== Modifiers
//

//== Defaults
//
