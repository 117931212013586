@import '../mixins/adaptive.less';

//== Mixins
//

//== Block
//

.primary-nav-first-level-item {
    display: flex;
    flex-wrap: wrap;

    list-style: none;

    padding: 0;
    border-top: 1px solid @nav-divider;

    &:last-child {
        border-bottom: 1px solid @nav-divider;
    }

    @media (min-width: @screen-md-max) {
        display: inline-block;

        padding-right: 14px;
        border: none;
        font-size: 14px;

        &:last-child {
            border-bottom: none;
        }
    }

    @media (min-width: 1201px) and (max-width: 1439px) {
        padding-right: 14px;
        border: none;
        font-size: 14px;
    }

    @media (min-width: 1440px) {
        font-size: 16px;
        padding-right: 20px;
    }
}

//== Elements
//

.primary-nav-first-level-item__title-link {
    display: block;
    flex: 1 1 0;

    color: @riba-slate;

    font-weight: bold;
    text-decoration: none;

    &:focus,
    &:active,
    &:hover {
        color: @riba-slate;
    }

    @media (max-width: @screen-md-max) {
        font-size: 16px;
        padding: 16px;
        line-height: 16px;
    }

    @media (min-width: @screen-md-max) {
        display: block;

        min-height: 34px;
        padding: 26px 0 20px;
        width: 88px;
        border-bottom: 6px solid transparent;

        line-height: 1.25;
        text-align: center;

        &:hover {
            min-height: 40px;
            color: @riba-black;
            border-bottom-color: @riba-stone-blue;

            ~ .primary-nav-first-level-item__items {
                display: block;
            }
        }

        .primary-nav-first-level-item--active & {
            border-bottom-color: @riba-stone-blue;
        }
    }

    @media (min-width: 1440px) {
        width: 100px;
        margin-top: 1px;
    }
}

.primary-nav-first-level-item__toggle-control {
    .material-icons();

    flex: 0 0 50px;

    font-size: 24px;
    padding: 0 12px;
    margin: 12px 0;

    border-left: 1px solid @nav-divider;

    .hideOn(min; @screen-lg-min);

    > .js-toggle-hidden {
        display: none;
    }
}

.primary-nav-first-level-item__items {
    flex: 0 0 100%;
    padding: 0;
    background: @riba-slate;

    &.js-toggle-hidden {
        .hideOn(max; @screen-md-max);
    }

    @media (min-width: @screen-lg-min) {
        display: none;
        position: absolute;

        width: 200px;
        padding: 28px 12px;

        &:hover {
            display: block;
        }
    }

    @media (min-width: 1440px) {
        width: 390px;
        column-count: 2;
    }
}

//== Modifiers
//
.primary-nav-first-level-item--active {
    background-color: @riba-stone-blue;

    @media (min-width: @screen-md-max) {
        background-color: transparent;
    }
}

//== Defaults
//
