//
// Styles for info-box molecule
// =========================================

//== Mixins
//

//== Block
//

.info-box {
    width: 100%;
    padding: 24px 20px;
    margin-bottom: 20px;

    color: @riba-slate;
    background-color: @info-primary;

    text-align: center;
    overflow-wrap: break-word;
}

//== Elements
//

.info-box__header {
    margin: 0;
    padding-bottom: 18px;

    text-transform: uppercase;
}

.info-box__content {
    line-height: 1.56;
    padding-bottom: 15px;
}

.info-box__information {
    padding-bottom: 8px;
}

.info-box__subtext {
    color: @riba-cool-grey;

    font-size: 14px;
}

.info-box .material-icons {
    line-height: 1.56;
    vertical-align: bottom;
    margin-right: 4px;

    font-size: 16px;
}

//== Modifiers
//

.info-box__content--grey {
    color: @riba-cool-grey;
}

.info-box__secondary {
    background-color: @riba-light-stone-blue;

    .info-box__content {
        font-size: 28px;
    }
}
