// Molecule: "error"
//

//== Variables
//

//== Mixins
//

//== Block
//

.information {
    padding: .844em 1.438em;
    border-left: 4px solid #5bc0de;
    width: 100%;

    background-color: #f4f8fa;

    @media(max-width: @screen-sm-min){
        margin-bottom: 2em;
    }
}

//== Elements
//

.information__header {
    height: 1.506em;
    line-height: 1.56;

    font-size: 1em;
    font-weight: bold;

    color: #5bc0de;

    .material-icons {
        margin-right: .5em;
        margin-bottom: .365em;
        vertical-align: middle;

        font-size: 1em;
    }
}

.information__content {
    line-height: 1.56;
    color: @riba-slate;
    font-size: 1em;
}

//== Modifiers
//


//== Defaults
//
