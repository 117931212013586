@import '../mixins/adaptive.less';

//
// Styles for form-heading molecule
// =========================================

//== Mixins
//

//== Block
//

//== Elements
//

.form-heading__title {
    margin-bottom: 10px;

    color: @riba-slate;

    font-weight: bold;
    text-transform: uppercase;
    font-size: 26px;

    @media (max-width: @screen-sm-min) {
        font-size: 20px;
    }
}

.form-heading__suptitle {
    margin-bottom: 18px;
    color: @riba-slate;
    font-size: 16px;
}

.form-heading__info {
    margin-top: 30px;
    font-size: 22px;

    @media (max-width: @screen-sm-min) {
        font-size: 18px;
    }
}

.form-heading__edit,
.form-heading__toggle {
    float: right;
    margin: 20px 0;

    @media (max-width: @screen-sm-min) {
        margin: 0;
    }
}

.form-heading__edit, .form-heading__toggle {
    float: right;
    margin: 1em 0;
}

//== Modifiers
//

.form-heading--shownOnlyOnXSmall {
    display: none;
    .showOn(max; @screen-sm-min);
}
