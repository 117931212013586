//
// Styles for list-selected-item atom
// =========================================

//== Mixins
//

//== Block
//

.list-selected-item {
    vertical-align: middle;
    padding: 5px 10px 5px 0;
    text-align: left;
    background: #f2f2f2;
    margin: 5px;
}

//== Elements
//

.list-selected-item__name {
    display: inline-block;

    vertical-align: middle;
    padding-left: 16px;

    color: @riba-slate;

    text-align: left;
    font-weight: bold;
    font-size: 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
}

.list-selected-item__delete {
    .material-icons();

    font-size: 18px;
    color: @riba-blue;

    vertical-align: middle;
    cursor: pointer;
}

//== Modifiers
//
