// Molecule: "Key Information"
//
// Provides a list of key information.

//== Variables
//

//== Mixins
//

//== Block
//

.key-information-list {
    margin: 0;
        @media (min-width: @screen-md-min) {
        width: 50%;
        float: left;
        margin: 1em 0;
        }
}

//== Elements
//

.key-information-list__item {
    font-size: 1.375em;
}

//== Modifiers
//

.key-information-list__item--space {
    margin-top: 1.5em;
}

//== Defaults
//
