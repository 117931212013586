@import '../mixins/adaptive.less';

//
// Styles for join-riba-form-content organism
// =========================================

//== Mixins
//

//== Block
//

//== Elements
//

.join-riba-form-content__form {
    display: inline-block;
    vertical-align: top;
    width: 59%;
    margin-right: 10%;

    @media(max-width: @screen-sm-min) {
        width: 100%;
        margin-right: auto;
    }
}

.join-riba-form-content__section {
    margin-bottom: 50px;

    @media(max-width: @screen-sm-min) {
        margin-bottom: 40px;
    }

    >.js-toggle-hidden {
        display: none;
    }
}

.join-riba-form-content__collapsableSection {
    &.js-toggle-hidden {
        display: none;
    }
}

.join-riba-form-content__info {
    float: right;
    vertical-align: top;
    width: 30%;

    @media(max-width: @screen-sm-min) {
        float: none;
        width: 100%;
    }
}

.join-riba-form-content__actions {
    margin: 88px auto;
    display: flex;

    text-align: right;
    justify-content: flex-end;

    .button {
        margin-left: 30px;
    }

    @media(max-width: @screen-sm-min) {
        margin: 40px auto;
        flex-wrap: wrap-reverse;

        text-align: center;

        .button {
            width: 100%;
            margin: 12px auto;
        }
    }
}

.join-riba-form-content__totop {
    display: none;

    .to-top {
        margin: 16px 0 32px 0;
    }

    .showOn(max; @screen-sm-min);
}

//== Modifiers
//

.join-riba-form-content__section--hiddenOnXSmall {
    .join-riba-form-content__section();
    .hideOn(max; @screen-sm-min);
}
