//
// Or Divider
// =========================================

//== Mixins
//

//== Block
//

.or-divider {
    text-transform: uppercase;
    font-weight: bold;
    width: 25%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height:0.1em;
    margin:10px auto 20px;

    span {
      background: #fff;
      padding: 0 10px;
    }

}

//== Elements
//

//== Modifiers
//
