// Molecule: "Whitespace"
//

//== Variables
//

//== Mixins
//

//== Block
//

.whitespace {

}

//== Elements
//

//== Modifiers
//

.whitespace--half {
    padding: 8px 0;
}

.whitespace--single {
    padding: 16px 0;
}

.whitespace--double {
    padding: 32px 0;
}
