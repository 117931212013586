@import '../themes/default/colours.less';
@import '../themes/default/config.less';

// Organism: "Join the RIBA"
//
// A call to action type organism which prompts and promotes visitors of the site to either renew their
// membership or to sign up to the RIBA as a member.

//== Variables
//

//== Mixins
//

//== Block
//
.join-the-riba {
    width: 100%;

    background-color: @riba-white;
    text-align: center;

    padding: 2em 0 2em;

    @media (min-width: @screen-md-min) {
        padding-bottom: 3.125em;
    }
}

//== Elements
//

.join-the-riba__title {
    margin: 0 auto .75em;

    text-transform: uppercase;
    font-weight: bold;
    line-height: 1em;
    font-size: 2.2em;
    color: @riba-slate;
}

.join-the-riba__introduction {
    margin: 0 auto 2em;
    width: 90%;

    font-size: 1em;
    line-height: 1.375em;
    color: @riba-slate;

    @media (min-width: @screen-md-min) {
        width: 60%;
    }
}

.join-the-riba__buttons {
    width: 100%;

    .button {
        margin: 0 .625em 1em;

        text-align: center;
        vertical-align: middle;

        @media (min-width: @screen-md-min) {
            margin-bottom: 0;
        }
    }
}

//== Modifiers
//

//== Defaults
//
