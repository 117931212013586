@import '../themes/default/colours.less';
@import '../themes/default/config.less';

// Molecule: "Footer Social Media Links"
//

//== Variables
//

//== Mixins
//

//== Block
//

.social-links {
    .clearfix();

    display: inline-block;

    margin: auto 0 0;
    padding: 0;

    list-style-type: none;

    li {
        display: inline-block;
    }
}


//== Elements
//

.social-links__link {
    float: left;

    margin-right: 1em;

    @media (min-width: @screen-sm-min) {
        margin-right: 1.25em;
    }

    @media (min-width: @screen-md-min) {
        margin-right: 0.625em;
    }
}

//== Modifiers
//

.social-links__link--last {
    margin-right: 0;
}
