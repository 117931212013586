@import '../mixins/adaptive.less';

//
// Styles for form-subheading atom
// =========================================

//== Mixins
//

//== Block
//

.form-subheading {
    font-weight: bold;
    font-size: 26px;
}

//== Elements
//

//== Modifiers
//

.form-subheading--hiddenOnXSmall {
    .hideOn(max; @screen-sm-min);
}
