//
// Heading Two
// =========================================

//== Mixins
//

//== Block
//

//== Elements
//
.heading-two__heading {
    margin: 1em 0;
    padding: 0;

    color: @riba-slate;
    font-size: 1.563em;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;

    @media (min-width: @screen-md-min) {
        font-size: 2.188em;
    }
}

//== Modifiers
//
