//
// Styles for upload-info atom
// =========================================

//== Mixins
//

//== Block
//

//== Elements
//

.upload-info__list {
    margin-top: 0px;
    font-size: 14px;
    line-height: 25px;
}

.upload-info__note {
    font-size: 14px;
}

//== Modifiers
//
