// Organism: "Search content"
//

//== Variables
//

//== Mixins
//

//== Block
//

//== Elements
//

.search-content__heading {
    text-transform: uppercase;
}

.search-content__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: @screen-sm-min) {
        flex-wrap: wrap;
    }
}

.search-content__page-controls {
    display: flex;
    align-items: center;
    flex: 0 0 auto;

    @media (max-width: @screen-sm-min) {
        width: 100%;
        margin-top: 30px;
    }
}

.search-content__search-bar {
    flex: 0 1 auto;
}

.search-content__page-size {
    flex: 0 0 auto;
    margin-left: 24px;

    font-size: 16px;
    font-weight: bold;

    @media (max-width: @screen-sm-min) {
        width: auto;
        flex: 1 1 auto;
        margin-left: 0;
    }
}

.search-content__pagination {
    float: none;
    margin: 0;
    flex: 0 0 auto;
    margin-left: 24px;

    @media (max-width: @screen-sm-min) {
        margin-left: 12px;
    }
}

.search-content__results {
    background: @riba-white;

    padding: 0 37px;

    @media (max-width: @screen-md-min) {
        padding: 32px 0 0 0;
    }
}

//== Modifiers
//

.search-content__pagination--bottom {
    float: none;
    flex: 0 0 auto;
    margin: 0;
    text-align: center;
    padding: 16px 0;
}

//== Defaults
//
