//
// Styles for selected-list-items organism
// =========================================

//== Mixins
//

//== Block
//

.selected-items__list {
  display: flex;
  flex-wrap: wrap;
}

//== Elements
//


//== Modifiers
//
