@import '../themes/default/colours.less';
@import '../themes/default/config.less';
@import '../mixins/link.less';
@import '../mixins/content-container.less';

// Organism: "Filters"
//

//== Block
//

.filters {
    display: none;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 0;

    z-index: 1;

    @media (min-width: @screen-xs-min) {
        background: rgba(51, 63, 72, 0.9);
    }

    @media (min-width: @screen-md-min) {
        position: absolute;
        top: 0;
        left: 1em;

        width: 267px;
        margin: 0;
        padding: 0;

        background: transparent;
    }

    @media (min-width: @screen-lg-min) {
        width: 367px;
    }

    &.filters--active {
        display: block;
    }
}

//== Elements
//

.filters__inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: white;
    overflow: hidden;

    @media (min-width: @screen-xs-min) {
        top: 1em;
        bottom: 1em;
        left: 3em;
        right: 3em;

        border: 1px solid @riba-slate;
    }

    @media (min-width: @screen-md-min) {
        position: absolute;
        top: 30px;
        bottom: 0;
        left: 0;
        right: 0;

        height: auto;
        width: 100%;

        border: none;
        border-right: 1px solid @riba-slate;
    }
}

.filters__header {
    display: block;
    height: 48.5px;

    margin: 0 .65em;
    padding: .7em .5em .9em;

    border-bottom: 1px solid @riba-slate;
    background: white;

    @media (min-width: @screen-md-min) {
        display: none;
    }
}

.filters__reset {
    display: none;
    position: relative;
    top: 4px;

    &.filter-toggle--selected {
        display: inline-block;
    }
}

.filters__close {
    margin: .375em 0 0;
}

.filters__apply {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    padding: .2em 0 .1em;
    max-width: 150px;

    @media (min-width: 425px) {
        max-width: 175px;
    }
}

.filters__body {
    padding: 0;
    margin: 0;

    list-style: none;
}

.filters__item {
    font-size: 1em;
    color: @riba-slate;

    .filters__body {
        display: none;
    }
}

.filters__link {
    position: relative;

    display: block;
    padding: .9em 0 .85em 1.875em;
    font-weight: normal;
    color: @riba-slate;

    &:focus,
    &:visited,
    &:link {
        font-weight: normal;
        color: @riba-slate;
    }

    //
    // Second level links are indented further than top level filter categories and first level filter categories.
    //
    .filters__body .filters__body .filters__body & {
        padding-left: 2.875em;

        &:before {
            left: 1.5em;

            @media (min-width: @screen-md-min) {
                left: 1.9em;
            }
        }

        @media (min-width: @screen-md-min) {
            padding-left: 3.275em;
        }
    }

    //
    // Third level links are indented more than all other filter levels.
    //
    .filters__body .filters__body .filters__body .filters__body & {
        padding-left: 3.875em;

        &:before {
            left: 2.5em;

            @media (min-width: @screen-md-min) {
                left: 2.9em;
            }
        }

        @media (min-width: @screen-md-min) {
            padding-left: 4.275em;
        }
    }

    //
    // An active link is a filter which has been selected. It displays a cross icon to signify that
    // selecting it again will deselect the filter, and has a darker background colour to display
    // that it is currently a "selected" filter.
    //
    &.filters__link--active {
        background-color: @riba-light-stone-blue;

        &:after {
            .material-icons();

            position: absolute;
            top: 50%;
            right: 11px;

            transform: translateY(-8px);
            color: @riba-cool-grey;
            content: 'clear';

            font-size: 1em;
        }
    }

    @media (min-width: @screen-md-min) {
        padding-top: .45em;
        padding-bottom: .4em;
        padding-left: 2.275em;
    }
}

//== Modifiers
//

//
// The initial body of filters must be a scrollable area inside a fixed container.
//
.filters__body--main {
    position: absolute;
    top: 48.5px;
    bottom: 0;
    left: .65em;
    right: .65em;

    overflow-y: auto;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;

    @media (min-width: @screen-md-min) {
        position: relative;
        top: 0;
        left: 0;
        right: 0;

        padding: 0 .65em;
    }
}

//
// The top level items should always display a border below the item when at mobile screen sizes.
//
.filters__item--top {
    border-bottom: 1px solid @riba-light-stone-blue;

    @media (min-width: @screen-md-min) {
        border-bottom: none;
    }
}

//
// Filter links which display/hide categories should display a + or - icon depending on whether
// the category of filters in question is considered to be active or not.
//
.filters__link--category {
    &:before {
        .material-icons();

        position: absolute;
        top: 50%;
        left: .5em;

        transform: translateY(-8px);
        color: @riba-slate;
        content: 'add';

        font-size: 1em;

        .filters--active > & {
            content: 'remove';
        }

        @media (min-width: @screen-md-min) {
            left: .9em;
        }
    }

    //
    // When a category link is open, displaying it's children, the text colour should be black.
    //
    .filters--active > & {
        color: black;
    }

    //
    // Similar to above, except that if the category link is also a top level link and is open, it should remain slate.
    //
    .filters--active.filters__item--top > & {
        color: @riba-slate;
    }
}

//
// Filter links which are titles (usually open the top-level categories) should always be bold.
//
.filters__link--title {
    font-weight: bold;

    &:focus,
    &:visited,
    &:link {
        font-weight: bold;
    }
}

//
// When a filter area is set as active the list of filters directly within it should be displayed.
//
.filters--active {
    > .filters__body {
        display: block;

        margin-bottom: .5em;
    }
}
