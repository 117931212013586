// Molecule: "Pagination"
//

//== Variables
//

//== Mixins
//

//== Block
//

.pagination {
    display: block;
    float: right;

    margin: 19px 14px 0 0;
}

//== Elements
//

.pagination__link {
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    text-align: center;
}

.pagination__icon {
    position: relative;
    top: 4.8px;

    font-size: 18px;

    @media (max-width: @screen-sm-min) {
        top: 6.9px;

        font-size: 24px;
    }
}

.pagination__text {
    font-size: 16px;
    line-height: 1.56;
}

//== Modifiers
//

.pagination__link--previous {
    margin-right: 0;

    @media (max-width: @screen-sm-min) {
        margin-right: 4px;
    }
}

.pagination__link--next {
    margin-left: 0;

    @media (max-width: @screen-sm-min) {
        margin-left: 4px;
    }
}

.pagination__link--number, .pagination__link--hellipsis {
    display: inline-block;

    width: 24px;

    @media (max-width: @screen-sm-min) {
        display: none;
    }
}

.pagination__link--hellipsis {
    color: @riba-cool-grey;
}

.pagination__link--active {
    border: 2px solid @riba-stone-blue;
    color: @riba-slate;
    font-weight: bold;
}
