// Molecule: "error"
//

//== Variables
//

//== Mixins
//

//== Block
//

.error {
    padding: 14px 23px;
    border-left: 4px solid @error-primary;
    min-height: 80px;
    width: 100%;

    background-color: @error-secondary;

    @media(max-width: @screen-sm-min) {
        margin-bottom: 32px;
        padding: 4px 23px;
        height: initial;
    }
}

//== Elements
//

.error__header {
    height: 24px;
    line-height: 24px;

    font-weight: bold;

    color: @error-primary;

    .material-icons {
        margin-right: 8px;
        margin-bottom: 6px;
        vertical-align: middle;

        font-size: 16px;
    }
}

.error__content {
    min-height: 24px;
    line-height: 24px;

    color: @riba-slate;
}

//== Modifiers
//

.error__content--strong {
    font-weight: bold;
    text-decoration: underline;
    color: @riba-slate;

    &:hover, &:active, &:focus {
        color: @riba-slate;
        text-decoration: underline;
    }
}

//== Defaults
//
