// Organism: "Home Hero Banner"
//
// A call to action type organism which promotes visitors of the site towards a single page via a
// large image along with a call to action title & button.

//== Variables
//

//== Mixins
//

//== Block
//

// The hero banner container uses an image as as a background on larger screens
.home-hero-banner {
    .content-container {
        padding: 0;
    }

    // The hero-banner should only contain styles on larger screens
    @media (min-width: @screen-md-min) {
        height: 23.75em;
        position: relative;
        width: 100%;

        background-color: @riba-white;
        background-position: center;
        background-size: cover;
    }

    @media (max-width: @screen-sm-max) {
        background-image: none !important;
    }
}

//== Elements
//

// The hero banner image is only shown on smaller screens in place of hero-banner above
.home-hero-banner__image {
    width: 100%;

    @media (min-width: @screen-md-min) {
        display: none;
    }
}

.home-hero-banner__content-container {
    @media (min-width: @screen-md-min) {
        height: 23.75em;
        padding: 0.667em;
    }
}

.home-hero-banner__content {
    background-color: @riba-stone-blue;

    // Center align vertically on larger screens
    @media (min-width: @screen-md-min) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        background-color: transparent;
    }
}

.home-hero-banner__title {
    padding: 0 0.667em; 

    text-transform: uppercase;
    font-size: 1.875em;
    font-weight: bold;
    line-height: 1.16;

    // Restrict width on larger screens
    @media (min-width: @screen-md-min) {
         width: 10.244em;
         padding: 0;
         margin-top: 0.2em;

         font-size: 2.813em;
    }
}

.home-hero-banner__title-link {
    color: @riba-slate;

    &:hover,
    &:active,
    &:focus {
        color: @riba-slate;
    }

    @media (min-width: @screen-md-min) {
        color: @riba-white;

        &:hover,
        &:active,
        &:focus {
            color: @riba-white;
        }
    }
}

.home-hero-banner__button-container {
    padding: 0 1.25em 1.25em 1.25em;

    text-align: center;

    @media (min-width: @screen-md-min) {
        padding: 0.5em 0;

        text-align: left;
    }
}

// Custom button styles due to hero banner specific differences
.home-hero-banner__button {
    width: 100%;
    padding: 0.5632em;

    background-color: @riba-slate;
    color: @riba-white;
    font-weight: normal;

    &:hover {
        color: @riba-white;
    }

    // Fixed with buttons on larger screens (but allow scope for larger text)
    @media (min-width: @screen-md-min) {
        width: 15.625em;
        min-width: 15.625em;

        background-color: #ecf0f3;
        border: 0;
        color: @riba-slate;

        &:hover {
            color: @riba-slate;
        }
    }
}

//== Modifiers
//
.home-hero-banner__title-link--alternative {
    @media (min-width: @screen-md-min) {
        color: @riba-slate;

        &:hover,
        &:active,
        &:focus {
            color: @riba-slate;
        }
    }
}

.home-hero-banner__button--alternative {
    @media (min-width: @screen-md-min) {
        background-color: @riba-slate;
        color: @riba-white;

        &:hover {
            color: @riba-white;
        }
    }
}

//== Defaults
//
