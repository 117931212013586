//
// Competition Type
// =========================================

//== Mixins
//

//== Block
//

.competition-type {
    margin: 1.5em 1em;
}

//== Elements
//
.competition-type__status,
.competition-type__category,
.competition-type__divider {
    color: @riba-cool-grey;
}

.competition-type__divider {
    padding: 0 .25em;
}   

//== Modifiers
//
