//
// Styles for form-step organism
// =========================================

//== Mixins
//

//== Block
//

.form-step {
    position: relative;

    margin: 2.25em auto;

    @media(max-width: @screen-sm-min) {
        margin: 0;
    }
}

//== Elements
//

.form-step__holder {
    display: inline-block;

    width: 24%;
}

.form-step__divider {
    position: absolute;
    top: 15%;
    margin: 0 14.2%;

    width: 20%;

    @media(max-width: @screen-md-min) {
        margin: 0 17%;

        width: 15%;
    }
}

//== Modifiers
//
