// Molecule: "Competition Call To Action"
//

//== Variables
//

//== Mixins
//

//== Block
//

//== Elements
//

.competition-cta__button {
    width: 100%;

    @media (min-width: @screen-sm-min) {
        display: inline-block;
        width: auto;
        min-width: 11em;
    }

    @media (min-width: @screen-lg-min) {
        min-width: 13.625em;
        margin-right: 0;
    }
}

.competition-cta__help {
    display: block;
    padding-top: .5em;
}

.competition-cta__content-container {
    padding: 0;
}

//== Modifiers
//
.competition-cta__button--with-margin {
    margin-top: 1.25em;

    @media (min-width: @screen-sm-min) {
        margin-top: 0;
        margin-left: 1em;
    }
}

.competition-cta__help--inverted,
.competition-cta__button--inverted {
    color: @riba-white;

    &:hover,
    &:focus,
    &:active {
        color: @riba-white;
    }
}

.competition-cta__help-link--inverted {
    color: @riba-stone-blue;

    &:hover,
    &:focus,
    &:active {
        color: @riba-stone-blue;
    }
}

.competition-cta__button--inverted {
    border-color: @riba-light-stone-blue;
}

.competition-cta__button--opaque-inverted {
    background-color: @riba-light-stone-blue;
    border-color: @riba-light-stone-blue;
    color: @riba-slate;

    &:hover,
    &:focus,
    &:active {
        color: @riba-slate;
    }
}

//== Defaults
//
