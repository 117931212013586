// Organism: "Find An Architect"
//
// A call to action type organism which promotes visitors of the site towards a single page via a
// large image along with a call to action title & button.

//== Variables
//

//== Mixins
//

//== Block
//

// The find an architect container uses an image as as a background on larger screens
.find-an-architect {
    padding-bottom: 32px;

    // .find-an-architect should only show background image on larger screens
    @media (min-width: @screen-sm-min) {
        padding-bottom: 0;

        height: 400px;
        position: relative;
        width: 100%;

        background-color: @riba-white;
        background-position: center;
        background-size: cover;
    }

    // do not display background image on smaller screens
    @media (max-width: @screen-xs-max) {
        background-image: none !important;
    }
}

//== Elements
//
.find-an-architect__content {
    padding: 0 20px;

    @media (min-width: @screen-md-min) {
        width: 75%;
    }

    @media (min-width: @screen-lg-min) {
        width: 50%;
    }
}

.find-an-architect__title {
    margin: 25px 0 17px;

    text-transform: uppercase;
    color: @riba-slate;
    font-size: 25px;
    line-height: 1.2;

    @media (min-width: @screen-sm-min) {
        margin: 70px 0 24px;
        font-size: 35px;
    }
}

.find-an-architect__intro {
    margin-bottom: 24px;

    line-height: 1.56;

    @media (min-width: @screen-sm-min) {
        margin-bottom: 30px;
    }
}

// The find an architect image is only shown on smaller screens
.find-an-architect__image {
    width: 100%;

    @media (min-width: @screen-sm-min) {
        display: none;
    }
}

.find-an-architect-search {
    @media (min-width: @screen-sm-min) {
        .clearfix();
    }
}

.find-an-architect__radio-button-group {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 3px;
}

.find-an-architect__radio-button {
    width: 100%;

    @media (min-width: @screen-sm-min) {
        display: inline-block;
        width: auto;
        margin-right: 20px;
    }
}

.find-an-architect-search__input {
    width: 100%;
    height: 40px;

    @media (min-width: @screen-sm-min) {
        float: left;
        width: 55%;

        border-right: 0;
    }
}

.find-an-architect-search__button {
    width: 100%;
    margin-top: 10px;

    @media (min-width: @screen-sm-min) {
        float: left;
        width: 45%;
        margin-top: 0;
    }
}

//== Modifiers
//

//== Defaults
//
