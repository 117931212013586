//
// Styles for Dashboard mobile
// =========================================
//== Block
//

.dashboard-mobile {
    background-color: #37474f;
    border-color: #37474f;
    border-bottom-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;

    a {
        outline: 0;
    }
    @media (max-width: 768px) {
        margin: 15px auto 0;
        width: 90%;
    }
}
//== Elements
//

.dashboard-mobile__user {
    position: relative;
    padding: 20px 20px 35px;
    display: block;
    margin-bottom: 20px;

    a {
        color: #ffffff;
    }
}

.dashboard-mobile__user::after {
    clear: left;
}

.dashboard-mobile__user__img {
    width: 36px!important;
    height: 36px!important;
    border-radius: 50%;
    float: left;
}

.dashboard-mobile__user__details {
    float: left;
    width: 75%;
    margin-left: 5%;
}

.dashboard-mobile__user__name {
    font-weight: 700;
    position: relative;
    top: 8px;
}

.dashboard-mobile__user__number {
    font-size: 11px;
    color: #a2a1a1;
}

.dashboard-mobile__navbar {
    margin-bottom: 0;
    border-width: 1px 0;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    border: 1px solid transparent;
    @media (min-width: 769px) {
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 0;
    }
}

.dashboard-mobile__caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    float: right;
    position: relative;
    top: 13px;
    font-style: normal;
    font-weight: 400;
    border: 0;
    margin: 0;
    width: auto;
    height: auto;
    text-align: center;
    margin-top: -1px;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    @media (max-width: 768px) {
        width: 5%;
    }
}

.dashboard-mobile__caret:after {
    content: '\E315';
    font-family: Material Icons;
    display: block;
    font-size: 16px;
    width: 16px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.dashboard-mobile-active {
    .dashboard-mobile__user a > .dashboard-mobile__caret:after {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .sidebar {
        @media (max-width: 768px) {
            display: block;
            width: 90%;
            margin: 0 auto;
        }
    }
}
//== Modifiers
//
//////////////////////////////////////
//
// Styles for dashboard container
// =========================================
//== Block
//

.dashboard-container {
    position: relative;
    @media (min-width: @screen-sm-min) {
        width: 100%;
        display: table;
        table-layout: fixed;
        height: 1px;
        min-height: 619px;
        @-moz-document url-prefix() {
            height: 1px;
            min-height: 619px;
        }
    }
}
//== Elements
//
//== Modifiers
//
//////////////////////////////////////
//
// Styles for dashboard content
// =========================================
//== Block
//
@media (min-width: 769px) {
    .dashboard-content {
        display: table-row;
    }
}
//== Elements
//

.dashboard-content-wrapper {
    width: 100%;
    background: #f3f4f5;
    @media (min-width: 769px) {
        display: table-cell;
        vertical-align: top;
    }

    .dashboard__panel {
      margin-bottom: 40px;
      border: 1px solid #dddddd;
      background: #ffffff;

      .error {
        @media(max-width: @screen-sm-min) {
          margin-bottom: 0;
        }

      }

      .table {
        margin-bottom: 0;
        border-bottom: 1px solid #dddddd;

        th, td {
          padding: 10px 20px;
        }
      }

      &> .dashboard__panel__heading + .dashboard__panel__body {
        padding-top: 0;
      }

      &> .dashboard__panel__body + .dashboard__panel__heading  {
        padding-top: 0;
      }


      .dashboard__panel {
        .information {
          background-color: #f4f8fa;
        }
      }


      .information {
        margin-bottom: 0;
      }

    }

    .dashboard__panel__body {
      padding: 20px;

      .join-riba-form-content__section {
        margin-bottom: 0;
      }

    }

    .dashboard__panel__heading {
      padding: 0px 20px 15px 20px;
      background: #ffffff;

      h5{
        margin-top: 2px;
        margin-bottom: 0;
        font-size: 1.4em;
        font-weight: 700;
      }

    }

    .info-box {
      background: #ffffff;
      border: 1px solid #dddddd;
    }

    .information {
      background: #ffffff;
    }

}

.dashboard__panel__body__actions {
    margin: 0 auto;
    display: flex;
    text-align: right;
    justify-content: flex-end;

    .button {
        margin-left: 30px;
    }

    @media(max-width: @screen-sm-min) {
        margin: 40px auto;
        flex-wrap: wrap-reverse;
        text-align: center;

        .button {
            width: 100%;
            margin: 12px auto;
        }
    }
}

.dashboard-modal {
    padding: 35px 20px 25px 20px;
    max-width: 700px;
    background: @riba-white;
    text-align: left;
    position: relative;

    .cta-band__buttons {
      text-align: center;
    }

    @media (max-width: @screen-md-min) {
        max-width: 380px;
    }

}

.dashboard-modal__close {
    .material-icons();
    position: absolute;
    top: 14px;
    right: 16px;
    color: @riba-slate;
    font-size: 24px;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus,
    &:visited {
        font-weight: normal;
        color: @riba-slate;
        text-decoration: none;
    }
}

.dashboard-modal__title {
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 4px;
    text-transform: uppercase;
}

.dashboard-modal__subtext {
    text-align: left;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 20px;
}

.project-uploader-form-content__section {
  .cta-band__buttons {
    text-align: center;
    margin: 50px auto 30px ;
  }

  .project-uploader-form-content__section__title {
    font-size: 36px;
    margin: 20px 20px 0 20px;
  }

  .form-value {
    max-width: 270px;
    word-wrap: break-word;
  }


  @media (max-width: @screen-md-min) {
    .upload__button {
      margin-bottom: 2em;
    }
  }

}

.project-uploader-form-content_images {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  .button {
    max-width: 100%;
  }

  .split-row {
    flex-grow: 1;

    @media (max-width: @screen-md-min) {
        margin-top: 0;
    }

    .split-container:nth-child(1) {
      @media (max-width: @screen-md-min) {
          margin-bottom: 2em;
      }
    }
  }

  .form-value-container__label {
    @media (min-width: @screen-md-min) {
        width: 20%;
    }
  }

}

//== Modifiers
//
//////////////////////////////////////
//
// Styles for dashboard-body-content
// =========================================
//== Block
//

.dashboard-body-content {
    padding: 0 20px 60px;

    .breadcrumbs {
      .large-content-container {
        max-width: none;
        padding: 0;
      }

      .breadcrumbs__list {
        margin-top: 0;
      }
    }

    .join-riba-form-content__info {
      @media(max-width: @screen-lg-min) {
          width: 100%;
      }
    }

}
//== Elements

.dashboard-body-content__form {
  display: inline-block;
  vertical-align: top;
  width: 67%;
  margin-right: 3%;

  @media(max-width: @screen-lg-min) {
      width: 100%;
      margin-right: auto;
  }
}

//
//== Modifiers
//
//////////////////////////////////////
//
// Styles for dashboard header
// =========================================
//== Block
//

.dashboard-header {
    margin: 0;
    padding: 0;
    border-bottom-width: 0;
}
//== Elements
//

.dashboard-header__content {
    position: relative;
    background-color: inherit;
    padding: 0 20px;
    background-color: #fff;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 1px 0 0 #ddd;
    box-shadow: 0 1px 0 0 #ddd;
}

.dashboard-header__content__title {
    padding: 32px 36px 32px 0;
    display: block;
    position: relative;
    font-size: 1.25em;
    @media (min-width: 769px) {
        padding-right: 0;
    }

    h1 {
        margin: 0;
    }

    h2 {
        margin: 0;
    }

    h3 {
        margin: 0;
    }

    h4 {
        margin: 0;
    }

    h5 {
        margin: 0;
    }

    h6 {
        margin: 0;
    }
}
//== Modifiers
//
//////////////////////////////////////
//
// Styles for dashboard sidebar
// =========================================
//== Block
//

.sidebar {
    z-index: 1;
    background-color: #263238;
    color: #fff;
    position: relative;
    width: 100%;
    display: none;
    @media (min-width: 769px) {
        display: table-cell;
        vertical-align: top;
        width: 260px;
    }
}
//== Elements
//

.sidebar__content {
    position: relative;
    padding-bottom: 0;
}

.sidebar__user {
    position: relative;
    padding: 20px;
    display: block;
    margin-bottom: 20px;

    a {
        float: left;
        width: 25%;
    }
}

.sidebar__user::after {
    clear: left;
}

.sidebar__user__img {
    width: 36px!important;
    height: 36px!important;
    border-radius: 50%;
}

.sidebar__user__details {
    float: left;
    width: 75%;
}

.sidebar__user__name {
    font-weight: 700;
}

.sidebar__navigation {
  .sidebar__user__number {
      font-size: 12px;
      color: rgba(255,255,255,.75);
      display: block;
      padding-left: 39px;
      padding-bottom: 15px;
  }
}


//== Modifiers
//
//////////////////////////////////////
//
// Styles for sidebar
// =========================================
//== Block
//
//== Elements
//

.sidebar__navigation {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
    }

    ul li {
        position: relative;

        a {
            color: rgba(255, 255, 255, .75);
            display: block;
            -webkit-transition: background 0.15s linear, color 0.15s linear;
            -o-transition: background 0.15s linear, color 0.15s linear;
            transition: background 0.15s linear, color 0.15s linear;
            outline: 0;

            &:focus,
            &:hover {
                background-color: #009775;
                color: #fff;
            }
        }
    }

    ul li.sidebar--active {
      /*background: #161e21;*/
    }

    ul li.sidebar--active ul li.sidebar--active {
      /*background: #263238;*/
    }

    ul li+li {
        /*margin-top: 1px;*/
    }

    ul li a > i {
        float: left;
        top: 0;
        margin-top: 2px;
        margin-right: 15px;
        -webkit-transition: opacity 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out;
    }

    ul > li > a {
        padding: 12px 20px;
        min-height: 44px;
        font-weight: 500;
    }

    ul > li ul {
        list-style: none;
        margin: 0;
        padding: 0;
        /*background-color: rgba(0, 0, 0, .15);*/
        -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, .2);
        box-shadow: 0 1px 0 rgba(255, 255, 255, .2);
    }

    ul > li ul li ul {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    ul > li ul li a {
        padding: 10px 20px 10px 30px;
        min-height: 40px;
        font-weight: normal;

        &:focus,
        &:hover {
            background-color: rgba(50, 63, 68, 1);
            color: #fff;
        }
    }

    ul > li > ul > li > ul > li > a {
        padding-left: 50px;

        &:before {
          background: #425668;
          bottom: auto;
          content: '';
          height: 8px;
          left: 30px;
          margin-top: 8px;
          position: absolute;
          right: auto;
          width: 8px;
          z-index: 1;
          border-radius: 50%;
        }

        &:after {
          border-left: 1px solid #425668;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 33px;
          content: '';
        }

        &:focus,
        &:hover {
            background-color: rgba(50, 63, 68, 1);
            color: #fff;
        }
    }

    ul > li > ul > li > ul > li > ul > li > a {
        padding-left: 91px;
    }

    ul > li > ul li:first-child {
        /*padding-top: 10px;*/
    }

    ul > li > ul li:last-child {
        /*padding-bottom: 10px;*/
    }

    ul li li a > i {
        float: none;
        color: #a5a5a5;
        font-size: 15px;
    }

    ul li a > i {
        top: 3px;
        position: relative;
    }

    ul li ul li a > i {
        top: 2px;
        position: relative;
    }

    ul > li > a {
        padding: 5px 20px;
    }

    ul > li > a span {
        position: relative;
        top: 7px;
    }
}
//== Modifiers
//

.sidebar__navigation ul li > .sidebar--has-ul {
    position: relative;
    padding-right: 40px;

    &:after {
        content: "\E315";
        font-family: Material Icons;
        font-size: 16px;
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -8px;
        right: 20px;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
        -webkit-transition: -webkit-transform 0.2s ease-in-out;
        -o-transition: -webkit-transform 0.2s ease-in-out;
        transition: -webkit-transform 0.2s ease-in-out;
    }
}

.sidebar__navigation ul li.sidebar--active > .sidebar--has-ul:after {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sidebar__navigation ul > li.sidebar--active > a {
    background-color: #009775;
    color: #fff;

    &:focus,
    &:hover {
        background-color: #009775;
        color: #fff;
    }

    > [class*=text-] {
        color: #fff;
    }
}

.sidebar__navigation ul > li > ul li.sidebar--active > a {
    background-color: rgba(50, 63, 68, 1);
    color: #fff;

    &:focus,
    &:hover {
        background-color: rgba(50, 63, 68, 1);
        color: #fff;
    }
}


.sidebar__navigation ul > li > ul li.sidebar--active > a.sidebar--has-ul {
    background-color: #263238;
    color: #fff;

    &:focus,
    &:hover {
        background-color: #263238;
        color: #fff;
    }
}

.sidebar__navigation ul.sidebar--hidden-ul {
    display: none;
}

//////////////////////////////////////
//
// Styles for navbar
// =========================================
//== Block
//

.primary-nav-user {
    cursor: pointer;
    @media (min-width: 769px) {
        float: right!important;
        margin-right: -20px;
    }
}
//== Elements
//

.primary-nav-user__menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 13px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box;
    @media (min-width: 769px) {
        left: auto;
        right: 0;
    }
    @media (max-width: 768px) {
        left: auto;
        right: 0;
        padding: 0;
        margin: 0;
    }
}

.primary-nav-user__nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    > li {
        position: relative;
        display: block;
        @media (max-width: 990px) {
            border-top: 1px solid rgba(0,0,0,.12);
        }

        > a {
            position: relative;
            display: block;
            padding: 9px 15px;

            &:focus,
            &:hover {
                text-decoration: none;
            }

            > img {
                max-width: none;
            }
        }
    }

    .open > a {
        border-color: #1e88e5;
        background-color: transparent;
        border-color: transparent;
        color: #333;

        .primary-nav-user__caret {
            &:after {
                content: '\E5C7';
                font-family: Material Icons;
                display: block;
                font-size: 24px;
                font-weight: 400;
                width: 16px;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &:focus,
        &:hover {
            border-color: #1e88e5;
            background-color: transparent;
            border-color: transparent;
            color: #333;
        }
    }

    .nav-divider {
        height: 1px;
        margin: 9px 0;
        overflow: hidden;
        background-color: #e5e5e5;
    }
}

.primary-nav-user__menu {
    > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.5384616;
        color: #333;
        white-space: nowrap;
        @media (max-width: 990px) {
            padding: 16px;
            line-height: 16px;
        }

        &:focus,
        &:hover {
            text-decoration: none;
            color: #333;
        }

        i {

        }
    }

    .active > a {
        color: #fff;
        text-decoration: none;
        outline: 0;

        &:focus,
        &:hover {
            color: #fff;
            text-decoration: none;
            outline: 0;
        }
    }

    .primary-nav-user__divider {
        height: 1px;
        margin: 9px 0;
        overflow: hidden;
        background-color: #e5e5e5;
        @media (max-width: 990px) {
            background-color: #2d373f;
        }
    }

    li {
        @media (max-width: 990px) {
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}

.open > .primary-nav-user__menu {
    display: block;
}
.primary-nav-user__caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    font-style: normal;
    font-weight: 400;
    border: 0;
    margin: 0;
    width: auto;
    height: auto;
    text-align: center;
    margin-top: 4px;
    margin-right: 6px;
    float: right;
    @media (max-width: 990px) {
        padding-left: 12px;
        border-left: 1px solid rgba(0, 0, 0, 0.12);
    }

    &:after {
        content: '\E5C5';
        font-family: Material Icons;
        display: block;
        font-size: 24px;
        font-weight: 400;
        width: 16px;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}
.primary-nav-user__header {
    margin: 0;
    text-align: left;
    @media (max-width: 990px) {
        width: 100%;
    }

    > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 20px;
        padding-top: 13px;
        padding-bottom: 13px;
    }

    > .primary-nav-user__details img {
        max-height: 30px;
        margin-top: -2.5px;
        border-radius: 50%;
    }

    > .primary-nav-user__details > a {
        padding-top: 6.5px;
        padding-bottom: 6.5px;
        color: #333f48;

        span {
            padding-top: 6.5px;
            padding-bottom: 6.5px;
            display: inline-block;
            padding-left: 7px;
        }
    }

    > li > .primary-nav-user__menu {
        margin-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        margin-top: 1px;
        border-top-width: 0;
    }
    @media (max-width: 990px) {
        .open .primary-nav-user__menu {
            position: static;
            float: none;
            width: auto;
            margin-top: 0;
            background-color: transparent;
            border: 0;
            box-shadow: none;
            background: #333f48;
            border-radius: 0;

            > li > a {
                color: #ffffff;

                &:focus,
                &:hover {
                    background-image: none;
                }
            }
        }
    }
    @media (min-width: 990px) {
        float: left;
        margin: 0 0 0 20px;

        > li {
            float: left;

            > a {
                padding-top: 13px;
                padding-bottom: 13px;
            }
        }
    }
}

//////////////////////////////////////
//
// Styles for dashboard tables navbar
// =========================================


.dashboard-data-table {
  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  table tr {
    border: 1px solid #ddd;
    padding: .35em;
  }

  table th,
  table td {
    padding: .725em;
  }

  @media screen and (max-width: 1024px) {
    table {
      border: 0;
      margin-left: 2%;
      margin-right: 2%;
      width: 95%;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      display: block;
      margin-bottom: 1em;
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .9em;
      text-align: right;
    }

    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
}

//== Modifiers
//
