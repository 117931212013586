// Organism: "Promoted Links"
//

//== Variables
//

//== Mixins
//

//== Block
//

.promoted-links {
    width: 100%;

    @media (min-width: @screen-sm-min) {
        .clearfix();
    }
}

//== Elements
//

.promoted-link-container {
    width: 100%;
    margin-bottom: 0.2em;

    @media (min-width: @screen-sm-min) {
        float: left;
        margin-right: .2%;
        margin-bottom: 0;
        width: 49.8%;

        &:last-child {
            margin-left: .2%;
            margin-right: 0;
        }
    }
}

//== Modifies
//

.promoted-links--join-riba {
    margin: 0;
}

//== Defaults
//
