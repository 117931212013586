// Organism: "Awards/Competitions Card Board"
//
// A board of awards/competition cards to be used across the site

//== Variables
//

//== Mixins
//

//== Block
//
.awards-competitions-card-board {
    padding-bottom: 1.25em;

    background-color: @riba-white;

    @media (min-width: @screen-md-min) {
        .clearfix();
        padding-bottom: 3.125em;
    }
}

//== Elements
//
.awards-competitions-card-board__title {
    margin: 1.1em 0 .9em;

    text-transform: uppercase;
    text-align: center;
    font-size: 1.563em;
    line-height: 1.2;
    color: @riba-slate;

    @media (min-width: @screen-md-min) {
        margin: 1.1em 0;
        font-size: 2.188em;
    }
}

.awards-competitions-card-board__cards {
    @media (min-width: @screen-md-min) {
        display: flex;
        flex-wrap: wrap;

        margin-bottom: 1em;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

//== Modifiers
//

//== Defaults
//
