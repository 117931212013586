//
// Styles for field-help molecule
// =========================================

//== Mixins
//

//== Block
//

.field-help {
    display: inline-block;
    position: relative;

    cursor: pointer;
    color: @field-help-primary;

    font-size: 16px;

    @media (max-width: @screen-sm-min) {
        width: 100%;
    }
}

//== Elements
//

.field-help__icon {
    display: inline-block;
    margin-right: 4px;

    .material-icons {
        vertical-align: middle;

        color: @riba-blue;

        font-size: 18px;
    }
}

.field-help__content {
    display: inline-block;

    font-weight: bold;

    border-bottom: dotted 1px @field-help-primary;
}

.field-help .tooltip {
    left: 21px;
}

//== Modifiers
//
