//
// Styles for radio-button atom
// =========================================

//== Mixins
//

//== Variables
//

@radio-button-size: 22px;
@radio-button-font-size: @radio-button-size;
@label-left-margin: @radio-button-size + 10px;

//== Block
//

//== Elements
//

.radio-button__group {
    margin-bottom: 10px;
    display: block;
}

.radio-button__input {
    display: none;
}

.radio-button__radio {
    width: @radio-button-size;
    height: @radio-button-size;
    float: left;

    &:after {
        .material-icons();

        position: absolute;
        font-size: @radio-button-font-size;

        color: @riba-stone-blue;
        content: 'radio_button_unchecked';
    }
}

.radio-button__label {
    margin: 0 0 0 @label-left-margin;
    vertical-align: top;
    font-weight: normal;
}

.radio-button__text {
    margin: 0;
}

.radio-button__description {
    margin: 0;
}

//== Modifiers
//

.radio-button__input:checked + .radio-button__radio:after {
    color: @riba-blue;
    content: 'radio_button_checked';
}
