@import '../themes/default/colours.less';

//
// Button
// =========================================

//== Mixins
//

//== Block
//

.button {
    display: inline-block;

    width: 15.625em;
    border: 2px solid @riba-slate;
    padding: .438em 0;
    line-height: 1.375;

    color: @riba-slate;
    cursor: pointer;
    font-weight: bold;
    font-size: 1em;
    text-align: center;
    text-transform: uppercase;
    background-color: transparent;

    &:hover, &:active, &:focus {
        color: @riba-slate;
        text-decoration: none;
    }

    &[disabled] {
        pointer-events: none;
        cursor: default;
        background-color: @field-help-primary;
        border: 2px solid @field-help-primary;
    }
}

//== Elements
//

//== Modifiers
//

.button--opaque {
    color: @riba-white;
    background-color: @riba-slate;

    &:hover, &:active, &:focus {
        color: @riba-white;
    }
}

.button--small {
    width: 8.125em;
    padding: .5em;
}

