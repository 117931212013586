//
// Styles for search-bar atom
// =========================================

//== Mixins
//

//== Block
//

.search-bar {
    position: relative;
    display: flex;
    width: 570px;

    @media (max-width: @screen-sm-min) {
        width: 100%;
    }
}

//== Elements
//

.search-bar__input {
    width: 100%;
    height: 40px;
    background-color: @riba-white;
    border: solid 1px @riba-stone-blue;
    padding-left: 65px;
}

.search-bar__button {
    display: none;
}

.search-bar__search-icon {
    .material-icons();

    position: absolute;
    top: 6px;
    left: 12px;

    color: @riba-slate;

    font-size: 30px;
    width: 40px;
    height: 30px;
}

.search-bar__close-icon {
    .material-icons();

    position: absolute;
    top: 12px;
    right: 18px;

    color: @riba-light-slate;

    font-size: 16px;
    width: 16px;
    height: 16px;
}


//== Modifiers
//

