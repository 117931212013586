// Organism: "Listing"
//
// A container for any number of list item molecules

//== Block
//

.listing {
    .clearfix();

    background: @riba-white;
    border-top: 1px solid @riba-slate;

    padding: 0 1em;
}

//== Elements
//

.listing__date {
    display: block;
    margin: 1.5em 0 .75em 0;

    color: @riba-slate;
    font-size: 1.125em;
    font-weight: bold;
}
