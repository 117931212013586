//
// Styles for form-label atom
// =========================================

//== Mixins
//

//== Block
//

.form-value {
    display: block;

    color: @riba-slate;
    font-size: 16px;
}

//== Elements
//

//== Modifiers
//
