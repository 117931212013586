@import './content-container.less';

.large-content-container() {
    .clearfix();
    .content-container();

    @media(min-width: 1440px) {
        max-width: 90em;
    }
}
