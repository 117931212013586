@import '../themes/default/colours.less';
@import '../themes/default/config.less';

// Molecule: "Footer Policy Links"
//

//== Variables
//

//== Mixins
//

//== Block
//

.footer-policies {
    padding: 0;
    margin-bottom: 18px;

    font-weight: bold;
    font-size: 14px;
    color: @riba-stone-blue;

    a {
        .link-variant(@riba-stone-blue, @riba-stone-blue, none);
    }

    @media (min-width: @screen-md-min) {
        margin-bottom: 22px;
    }
}

//== Elements
//

.footer-policies__separator {
    color: @riba-stone-blue;
    padding: 0 7px;
}

//== Defaults
//
