// Organism: "Call To Action Hero"
//
// Provides key information about an event or competition.
//
// Small Screens:
// -----------
// |  image  |
// | content |
// | buttons |
// -----------
//
// Medium Screens:
// -------------------
// | content |
// |      bg-image  ->
// | buttons |
// -------------------
//
// Large Screens:
// ---------------------------------
//          |    content    |
// <- bleed |            bg-image ->
//          |    buttons    |
// ---------------------------------
//
//== Variables
//
@content-md-width: 29em;
@content-lg-width: 41em;
@content-md-background-position-x: 20em;
@content-lg-background-position-x: 30em;
@screen-xl-min: 1440px;

//== Mixins
//

//== Block
//

.call-to-action-hero {
    position: relative;

    @media (max-width: @screen-sm-max) {
        background-image: none !important;
    }

    @media (min-width: @screen-md-min) {
        background-color: @riba-slate;
        background-position: @content-md-background-position-x center;
        background-repeat: no-repeat;
        min-height: 35em;
    }

    @media (min-width: @screen-lg-min) {
        background-position: @content-lg-background-position-x center;
    }

    @media (min-width: @screen-xl-min) {
        background-position: right center;
        background-size: 65%;
    }
}

//== Elements
//
.call-to-action-hero__content-container {
    padding-left: 0;
    padding-right: 0;

    @media (min-width: @screen-md-min) {
        min-height: 35em; // set a minimum height to provide consistency across pages
        padding-bottom: 8em; // allow room for absolutely positioned .call-to-action-hero__buttons
    }
}

.call-to-action-hero__large-content-container {
    @media (max-width: @screen-md-min) {
        padding-left: 0;
        padding-right: 0;
    }
}

.call-to-action-hero__image {
    width: 100%;

    @media (min-width: @screen-md-min) {
        display: none;
    }
}

.call-to-action-hero__image-promo {
    display: block;
    padding: .5em 0;

    font-size: 1em;
    background: @riba-light-stone-blue;

    @media (min-width: @screen-md-min) {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: .5em .25em
    }
}

.call-to-action-hero__content {
    padding: 1.4em 1em 1.875em 1em;

    background: @riba-slate;

    @media (min-width: @screen-md-min) {
        width: @content-md-width;
        min-height: 35em;
        padding-left: 0;

        padding-right: 1em;
    }

    @media (min-width: @screen-lg-min) {
        width: @content-lg-width;

        padding-right: 2.5em;
    }
}

.call-to-action-hero__tag {
    color: @riba-white;
    font-size: .75em;
    font-weight: bold;
    letter-spacing: .188em;
    text-transform: uppercase;
}

.call-to-action-hero__tag-divider {
    padding: 0 .75em;

    color: @riba-cool-grey;
    font-size: .75em;
}

.call-to-action-hero__title {
    margin-bottom: .7em;

    color: @riba-white;
    font-size: 1.875em;
    line-height: 1.17;
    text-transform: uppercase;

    @media (min-width: @screen-lg-min) {
        margin-top: .3em;
        margin-bottom: .5em;

        font-size: 2.813em;
    }
}

.call-to-action-hero__intro {
    margin: 1em 0;

    color: @riba-white;
    line-height: 1.56;

    @media (min-width: @screen-lg-min) {
        font-size: 1.125em;
    }
}

.call-to-action-hero__list {
    margin: 2.6em 0;
    padding: 0;
}

.call-to-action-hero__list-item {
    position: relative;
    margin-bottom: .7em;
    padding-left: 2.25em;

    list-style: none;
    color: @riba-white;
    line-height: 1.25;
}

.call-to-action-hero__list-item-link {
    color: @riba-stone-blue;
    font-weight: bold;

    &:hover,
    &:focus,
    &:active {
        color: @riba-stone-blue;
    }
}

.call-to-action-hero__list-icon {
    position: absolute;
    left: 0;
    top: .1em;

    color: @riba-light-stone-blue;
    font-size: 1.063em;
}

.call-to-action-hero__buttons {
    @media (min-width: @screen-md-min) {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
    }
}

//== Modifiers
//
.call-to-action-hero__tag--sub {
    color: @riba-cool-grey;
}

//== Defaults
//
