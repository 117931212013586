@import '../themes/default/colours.less';

//
// Filter Toggle Icon/Link
// =========================================

//== Mixins
//

//== Block
//

.filter-toggles {
    float: left;

    margin-left: .9em;

    .clearfix();

    @media (min-width: @screen-sm-min) {
        width: 142px;
    }

    @media (min-width: @screen-md-min) {
        margin: 0 .9em;
        padding: 0 .65em;
        width: 242px;
    }

    @media (min-width: @screen-lg-min) {
        width: 342px;
    }
}

.filter-toggle {
    position: relative;
    display: block;
    float: left;

    margin: 1.5em 0 1em;

    line-height: 1em;
}

//== Elements
//

.filter-toggle__icon {
    position: relative;
    top: 2px;
    left: -2px;

    font-size: 1em;
}

//== Modifiers
//

.filter-toggle__icon--filter {
    position: absolute;
    top: -1px;
}

.filter-toggle--main {
    padding-left: 1.25em;
}

.filter-toggle--reset {
    display: none;

    margin-top: 1.5em;
    margin-left: .5em;
    padding-left: .5em;

    border-left: 1px solid @riba-slate;
}

.filter-toggle--selected {
    display: inline-block;
}

.filter-toggle--close {
    display: none;

    &.filters--active {
        @media (min-width: @screen-sm-min) {
            display: block;
            float: right;
            height: 16px;

            color: @riba-slate;
        }

        .filters & {
            display: block;
            float: right;

            color: @riba-slate;

            @media (min-width: @screen-md-min) {
                display: none;
            }
        }
    }
}
