// Molecule: "Membership Benefit Card"
//

//== Variables
//

//== Mixins
//

//== Block
//

.membership-benefit-card {
    margin-bottom: .625em;

    background-color: @riba-light-grey;

    // Rows of two on small-large screens
    @media (min-width: @screen-sm-min) {
        flex: 1 1 49.25%;
        width: 49.25%;
        min-height: 26em;
        margin-right: 1.5%;
        margin-bottom: 1.5%;

        // Remove margin from every second item
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
}

//== Elements
//
.membership-benefit-card__image {
    width: 100%;
}

.membership-benefit-card__content {
    padding: 0 .625em;

    font-weight: normal;

    @media (min-width: @screen-sm-min) {
        padding: 0 1.25em;
    }
}

.membership-benefit-card__title {
    margin-top: 0;
    margin-bottom: .4em;
    padding-top: 1em;

    color: @riba-slate;
    text-transform: uppercase;
    line-height: 1.25;
    font-size: 1.375em;

    @media (min-width: @screen-sm-min) {
        margin-bottom: 0;
        padding-top: 1.25em;

        font-size: 1.625em;
    }
}

.membership-benefit-card__rich-text {
    .content-container {
        padding-left: 0;
        padding-right: 0;
    }
}

//== Defaults
//
