//
// To Top
// =========================================

//== Mixins
//

//== Block
//

.to-top {
    margin: 3em 0 2em 0;

    text-align: center;
}

//== Elements
//
.to-top__link {
    display: inline-block;
}

.to-top__icon {
    border-radius: 50%;

    background: @riba-blue;
    color: @riba-white;
    font-size: 1.375em;
    line-height: 1em;
}

.to-top__label {
    display: block;

    color: @riba-slate;
    font-size: 1.125em;
    font-weight: normal;
}

//== Modifiers
//
