// Organism: "Event Card Board"
//
// A board of event cards to be used across the site

//== Variables
//

//== Mixins
//

//== Block
//
.event-card-board {
    padding-bottom: 1.25em;

    background-color: @riba-stone-blue;

    @media (min-width: @screen-md-min) {
        .clearfix();
        padding-bottom: 3.125em;
    }
}

//== Elements
//
.event-card-board__title {
    margin: 1.1em 0 .9em;

    text-transform: uppercase;
    text-align: center;
    font-size: 1.563em;
    line-height: 1.2;
    color: @riba-slate;

    @media (min-width: @screen-md-min) {
        margin: 1.1em 0;
        font-size: 2.188em;
    }
}

.event-card-board__cards {
    @media (min-width: @screen-md-min) {
        display: flex;
        flex-wrap: wrap;
    }
}

//== Modifiers
//
.event-card-board__cta {
    margin-top: 1.875em;

    text-align: center;

    @media (min-width: @screen-md-min) {
        margin-top: 3.125em;
    }
}

//== Defaults
//
