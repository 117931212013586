//== Mixin utilities


//== Transition
.transition(@prop, @time: 0.5s, @type: ease) {
    -webkit-transition: @prop @time @type;
    -moz-transition: @prop @time @type;
    -ms-transition: @prop @time @type;
    -o-transition: @prop @time @type;
    transition: @prop @time @type;
}

//== Opacity
.opacity (@opacity: 0.5) {
    -webkit-opacity: @opacity;
    -moz-opacity: @opacity;
    opacity: @opacity;
}

//== Gradient
.gradient (@startColor: #eee, @endColor: white) {
    background-color: @startColor;
    background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
    background: -webkit-linear-gradient(top, @startColor, @endColor);
    background: -moz-linear-gradient(top, @startColor, @endColor);
    background: -ms-linear-gradient(top, @startColor, @endColor);
    background: -o-linear-gradient(top, @startColor, @endColor);
}

//== Remove default appearance
.appearance (@value: none) {
    -webkit-appearance: @value;
    -moz-appearance: @value;
    -ms-appearance: @value;
    -o-appearance: @value;
    appearance: @value;
}

