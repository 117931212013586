// Link mixin for styling links

.link-variant(@color; @stateColor; @decoration) {
    color: @color;

    &:hover, &:active, &:focus, &:visited {
        color: @stateColor;
        text-decoration: @decoration;
    }
}
