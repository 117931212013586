//
// Styles for resource-categories atom
// =========================================

//== Mixins
//

//== Block
//

.resource-categories {
    font-size: 16px;
    line-height: 1.56;
    color: @riba-cool-grey;
    padding-bottom: 30px;
}

//== Elements
//

//== Modifiers
//
