// Modal

.modal {
    vertical-align: middle;

    font-size: 16px;
    line-height: 25px;

    z-index: 9;

    @media (max-width: @screen-xs-max), (max-height: @screen-xs-max) {
        vertical-align: top;
    }
}

.remodal, .remodal-wrapper:after {
    vertical-align: middle;

    @media (max-width: @screen-xs-max), (max-height: @screen-xs-max) {
        vertical-align: top;
    }
}

.remodal-overlay {
    background: @modal-background;
}

.remodal-is-closed {
    display: none;
}
