//
// CPD Logo
// =========================================

//== Mixins
//

//== Block
//

.cpd {
    width: 100%;
    margin: 1em 0;

    @media (min-width: @screen-md-min) {
        text-align: right;
    }
}

//== Elements
//

//== Modifiers
//
