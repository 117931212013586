// Molecule: "Login popup"
//

//== Variables
//

//== Mixins
//

//== Block
//

.remove-staff {
    padding: 35px 20px 25px 20px;
    max-width: 700px;
    background: @riba-white;
    text-align: left;
    position: relative;

    @media (max-width: @screen-md-min) {
        max-width: 380px;
    }

}

//== Elements
//

.remove-staff__close {
    .material-icons();

    position: absolute;
    top: 14px;
    right: 16px;

    color: @riba-slate;
    font-size: 24px;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus,
    &:visited {
        font-weight: normal;
        color: @riba-slate;
        text-decoration: none;
    }
}

.remove-staff__title {
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 4px;
    text-transform: uppercase;
}

.remove-staff__subtext {
    text-align: left;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 20px;
}

.remove-staff__CP-ARB-eligibility {
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    margin: 9px 0;
}

//== Modifiers
//

//== Defaults
//
