// Molecule: "Breadcrumbs"
//

//== Variables
//

//== Mixins
//

//== Block
//

.breadcrumbs {
    display: none;
    color: @riba-slate;

    @media (min-width: @screen-md-min) {
        display: block;
    }
}

//== Elements
//
.breadcrumbs__list {
    padding: 0;
    margin: 0.9em 0;

    list-style: none
}

.breadcrumbs__item {
    display: inline-block;
    position: relative;

    padding-right: 1.2em;
    line-height: 1.8;
    font-size: .875em;
}

.breadcrumbs__link {
    font-weight: bold;
}

.breadcrumbs__divider {
    position: absolute;
    top: .5em;
    right: 0;

    color: @riba-slate;
    font-size: .875em;
}

//== Modifiers
//
