// Organism: "UMP Practice Page Item"
//

//== Block
//

.practice-page-item-placeholder {
  display: -webkit-flex; /* Safari */
  display: flex;
  -webkit-flex-direction: row; /* Safari */
  flex-direction: row;
  -webkit-flex-wrap: wrap; /* Safari */
  flex-wrap: wrap;
  justify-content: space-between;
}

.practice-page-item {
  border: 1px solid #ecf0f3;
  width: 48%;
  padding: 0.7em;
  flex-flow: 1;
  margin-bottom: 1.5em;
}

//== Elements

.practice-page-item__title {
  margin-top: 0;
  margin-bottom: 0;
}

.practice-page-item__address {
  color: #949494;
  font-size: 0.9em;
  font-weight: 400;
}

//
