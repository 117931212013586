//
// Styles for upload-document molecule
// =========================================

//== Mixins
//

//== Block
//

.upload-document {
    padding-right: 10%;

    @media(max-width: @screen-md-min) {
        padding-right: 0;
    }
}

//== Elements
//

.upload-document__header {
    margin-bottom: 1em;
}

.upload-document__section {
    margin-bottom: 1em;
}

//== Modifiers
//

.upload-document--light {
    color: @field-help-primary;
}
