//
// Styles for organisation-list atom
// =========================================

//== Mixins
//

//== Block
//

.organisation-list {
    font-size: 13px;
    column-count: 2;
    padding: 0 14px;
}

//== Elements
//

.organisation-list__item {
    list-style-type: none;
}

.organisation-list__country {
    font-weight: bold;
}

//== Modifiers
//
