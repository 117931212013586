// Container for splitting content 50/50

.split-row {
    display: block;
    margin: 2em auto;
}

.split-container {
    display: inline-block;
    width: 49%;
    vertical-align: top;

    @media(max-width: @screen-sm-min) {
        width: 100%;
        float: none;
    }
}
