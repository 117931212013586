// Molecule: "Event Card"
//

//== Variables
//
@content-horizontal-padding: 1.25em;

//== Mixins
//

//== Block
//

.event-card {
    display: block;
    position: relative;
    margin-bottom: .625em;

    background-color: @riba-white;

    @media (min-width: @screen-md-min) {
        flex: 1 1 30.3%;
        width: 30.3%;
        margin-right: 1.5%;
        margin-bottom: 0;
        min-height: 32em;

        &:last-child {
            margin-right: 0;
        }
    }
}

//== Elements
//
.event-card__image {
    width: 100%;
}

.event-card__content {
    padding-left: @content-horizontal-padding;
    padding-right: @content-horizontal-padding;
    padding-bottom: 2.5em;
}

.event-card__date {
    display: block;
    padding: 1.313em 0;

    color: #ef6079;
    text-transform: uppercase;
    letter-spacing: .169em;
    font-size: .75em;
    font-weight: bold;

    @media (min-width: @screen-md-min) {
        padding: 1.938em 0 1.313em;
    }
}

.event-card__title {
    margin-top: .125em;

    color: @riba-slate;
    text-transform: uppercase;
    font-size: 1.125em;
    line-height: 1.39;
}

.event-card__synopsis {
    margin: 1em 0;

    color: @riba-slate;
    font-size: 1em;
    font-weight: normal;
    line-height: 1.56;
}

.event-card__location {
    position: absolute;
    bottom: 1em;
    left: @content-horizontal-padding;

    font-weight: normal;
    color: @riba-cool-grey;
}

//== Defaults
//
