//
// Links
// =========================================
a,
a:link,
a:hover,
a:visited,
a:active {
    font-weight: bold;
}
