// Organism: "Key Information Board"
//
// Provides a list of key information.

//== Variables
//

//== Mixins
//

//== Block
//

.key-information-board {
    background-color: @riba-stone-blue;
    padding: 2em;
}

//== Elements
//

.key-information-board__title {
    font-size: 2.188em;
    margin: 0;
}

//== Modifiers
//

//== Defaults
//
