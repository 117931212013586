// Molecule: "Rich Text"
//
// This is a generic set of styles applied to rich text content from Gather Content.  These are sensible defaults
// however can be overridden by using higher specificity class selectors on individual components if required.

.rich-text {
    color: @riba-slate;
    font-family: @font-family-normal;

    h1 {
        font-size: 1.875em;
        font-weight: bold;

        @media (min-width: @screen-md-min) {
            font-size: 2.813em;
        }
    }

    h2 {
        margin: 0 0 .5em;

        font-size: 1.563em;
        font-weight: bold;
        line-height: 1.2;

        @media (min-width: @screen-md-min) {
            font-size: 2.188em;
        }
    }

    h3 {
        font-size: 1.4em;
        font-weight: bold;

        @media (min-width: @screen-md-min) {
            font-size: 1.625em;
        }
    }

    h4,
    h5,
    h6 {
        margin: 0;

        font-size: 1.375em;
    }

    blockquote {
        font-style: italic;
        font-size: 1.375em;
    }

    table {
        width: 100%;
    }

    p,
    li,
    td {
        font-size: 1.125em;
        line-height: 1.5;

        @media (min-width: @screen-md-min) {
            line-height: 1.41;
            font-size: 1.375em;
        }
    }

    ul {
        margin-top: 1.75em;
        margin-left: .5em;
        margin-right: 2em;

        @media (min-width: @screen-md-min) {
            margin-left: 2em;
        }
    }

    li {
        padding: .25em 0;
    }
    a:link,
    a:visited,
    a:hover,
    a:active,
    a {
        color: @riba-blue;
    }

    img {
        margin-bottom: 1em;
    }
}

