@import '../themes/default/colours.less';

//
// Toggle Button
// =========================================

//== Mixins
//

//== Variables
//

@toggle-button-font-size: 18px;

//== Block
//

.toggle-button {
    vertical-align: middle;
    padding-left: 1.25em;

    &:after {
        .material-icons();
        font-size: @toggle-button-font-size;

        color: @riba-slate;
        content: 'remove';

        vertical-align: middle;
        padding-right: 5px;
    }

    &.js-toggle-hidden:after {
        content: 'add';
    }
}

//== Elements
//

//== Modifiers
//
