// Organism: "Membership Type Card Board"
//
// A board of membership cards to be used across the site

//== Variables
//

//== Mixins
//

//== Block
//
.membership-type-card-board {
    padding-bottom: 1em;
    margin-top: 1em;

    background-color: @riba-light-stone-blue;

    @media (min-width: @screen-sm-min) {
        .clearfix();
        margin-top: 2.5em;
        padding-bottom: 2.5em;
    }

    .large-content-container {
        @media (max-width: @screen-sm-min) {
            padding: 0;
        }
    }
}

//== Elements
//
.membership-type-card-board__title {
    margin: 1.1em 0 .5em;
    padding: 0 1em;

    text-transform: uppercase;
    text-align: center;
    font-size: 1.563em;
    line-height: 1.2;
    color: @riba-slate;

    @media (min-width: @screen-sm-min) {
        padding: 0;

        font-size: 2.188em;
    }
}

.membership-type-card-board__intro {
    padding: 0 1em .5em 1em;
    text-align: center;
    font-size: 1em;
    line-height: 1.56;

    @media (min-width: @screen-sm-min) {
        padding: 0;
        max-width: 52.222em;
        margin: 0 auto 2em auto;

        line-height: 1.39;
        font-size: 1.125em;
    }
}

.membership-type-card-board__cards {
    @media (min-width: @screen-sm-min) {
        display: flex;
        flex-wrap: wrap;
    }
}

//== Modifiers
//

//== Defaults
//
