@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons'),
         local('MaterialIcons-Regular'),
         url(../../fonts/MaterialIcons-Regular.woff2) format('woff2'),
         url(../../fonts/MaterialIcons-Regular.woff) format('woff');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.875em; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';

    /* Rules for sizing the icon. */
    &.md-18 {
        font-size: 18px;
    }

    &.md-24 {
        font-size: 24px;
    }

    &.md-36 {
        font-size: 36px;
    }

    &.md-48 {
        font-size: 48px;
    }

    /* Rules for using icons as black on a light background. */
    &.md-dark {
        color: rgba(0, 0, 0, 0.54);

        &.md-inactive {
            color: rgba(0, 0, 0, 0.26);
        }
    }

    /* Rules for using icons as white on a dark background. */
    &.md-light {
        color: rgba(255, 255, 255, 1);

        &.md-inactive {
            color: rgba(255, 255, 255, 0.3);
        }
    }
}