//
// Styles for selected-list-items organism
// =========================================

//== Mixins
//

//== Block
//

.dropdown-list-select {
  margin-bottom: 20px;
}

//== Elements
//

.dropdown-list-select {
  select {
    min-width: 310px;
    cursor: pointer;
    @media (max-width: 960px) {
        margin-bottom: 10px;
    }
  }

  .button {
    @media (max-width: 960px) {
        width: 100%;
    }
  }
}

//== Modifiers
//
