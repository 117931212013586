//
// Styles for form-label atom
// =========================================

//== Mixins
//

//== Variables
//

@label-padding: 10px;

//== Block
//

.form-label {
    display: block;
    padding-right: @label-padding;
}

//== Elements
//

.form-label__title {
    color: @riba-slate;

    font-weight: bold;
    font-size: 18px;
}

.form-label__subtext {
    color: @riba-light-slate;

    font-size: 16px;
}

//== Modifiers
//
