@import '../mixins/content-container.less';
@import '../mixins/narrow-content-container.less';
@import '../mixins/large-content-container.less';
@import '../mixins/split-container.less';

//
// Containers
// =========================================

.container-fluid {
    max-width: @max_width;
    margin: 0 auto;
}

.content-container {
    .content-container();
}

.narrow-content-container {
    .narrow-content-container();
}

.large-content-container {
    .large-content-container();
}

.split-container {
    .split-container();
}
