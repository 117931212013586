// Organism: "Image Link Collection"
//
// A collection of image links with a title

//== Variables
//

//== Mixins
//

//== Block
//
.image-link-collection {
    margin: 1.5em 0;
}

//== Elements
//
.image-link-collection__title {
    margin: 1em 0;

    font-size: 1em;
    font-weight: normal;
    color: lighten(@riba-slate, 50%);
}

//== Modifiers
//

//== Defaults
//
