// Molecule: "Featured Content Card"
//

//== Variables
//
@card-margin: 16px;
@card-padding: 10px;

@card-image-width: 104px;
@card-image-right-margin: 10px;

@card-content-offset: @card-padding + @card-image-width + @card-image-right-margin;

//== Mixins
//

//== Block
//

.featured-content-card {
    .clearfix();
    display: block;
    position: relative;
    margin-bottom: 2px;
    padding: @card-padding;

    background-color: @riba-light-grey;

    // Rows of two on small-medium screens
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        flex: 0 1 auto;
        width: ~'calc(50% - (1/2 * @{card-margin}))';
        margin-right: @card-margin;
        margin-bottom: @card-margin;
        padding: 0;
        min-height: 416px;

        // Remove margin from every second item
        &:nth-child(2n) {
            margin-right: 0;
        }
    }

    // Rows of three on medium-large screens
    @media (min-width: @screen-md-min) {
        flex: 0 1 auto;
        width: ~'calc(33.3% - (2/3 * @{card-margin}))';
        margin-right: @card-margin;
        margin-bottom: @card-margin;
        padding: 0;
        min-height: 416px;

        // Remove margin from every third item
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}

//== Elements
//
.featured-content-card__image {
    float: left;
    width: @card-image-width;
    margin: 2px @card-image-right-margin 2px 2px;

    @media (min-width: @screen-sm-min) {
        width: 100%;
        margin: 0;
    }
}

.featured-content-card__content {
    float: left;
    width : ~'calc(100% - @{card-content-offset})'; // Allows for featured-content-card__image

    @media (min-width: @screen-sm-min) {
        width: 100%;
        padding: 0 20px;
    }
}

.featured-content-card__title {
    margin: 0;

    color: @riba-slate;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.25;

    @media (min-width: @screen-sm-min) {
        margin: 26px 0 13px 0;

        font-size: 26px;
    }
}

.featured-content-card__synopsis {
    margin: 0;

    color: @riba-slate;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.56;

    @media (min-width: @screen-sm-min) {
        font-size: 18px;
        padding-bottom: 18px;
    }
}

//== Modifiers
//

//== Defaults
//
