// Organism: "Card Board"
//
// A board of cards to be used across the site

//== Variables
//

//== Mixins
//

//== Block
//
.card-board {
    padding-bottom: 1.25em;

    background-color: @riba-white;

    @media (min-width: @screen-md-min) {
        .clearfix();
        padding-bottom: 3.125em;
    }
}

//== Elements
//
.card-board__title {
    margin: 1.1em 0 .9em;

    text-transform: uppercase;
    text-align: center;
    font-size: 1.563em;
    line-height: 1.2;
    color: @riba-slate;

    @media (min-width: @screen-md-min) {
        margin: 1.1em 0;
        font-size: 2.188em;
    }
}

.card-board__text {
    margin-top: .5em;
    margin-bottom: 1.25em;

    text-align: center;
    font-size: 1.125em;
    line-height: 1.56;

    @media (min-width: @screen-sm-min) {
        max-width: 52.222em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.5em;

        line-height: 1.41;
        font-size: 1.375em;
    }
}

.card-board__cards {
    @media (min-width: @screen-md-min) {
        display: flex;
        flex-wrap: wrap;

        margin-bottom: 0;
    }
}

.card-board__cta {
    margin-top: 1.875em;

    text-align: center;

    @media (min-width: @screen-md-min) {
        margin-top: 3.125em;
    }
}

//== Modifiers
//

.card-board--light {
    background-color: @riba-light-stone-blue;
}

.card-board--medium {
    background-color: @riba-stone-blue;
}

.card-board--with-text {
    .card-board__title {
        margin-bottom: .25em;
    }
}

.card-board--featured-content {
    .card-board__cards {
        @media (min-width: @screen-sm-min) {
            display: flex;
            flex-wrap: wrap;

            margin-bottom: 0;
        }
    }
}
