//
// Styles for singleline-text-input atom
// =========================================

//== Mixins
//

//== Block
//

.singleline-text-input {
    padding: 8px 20px;
    line-height: 1.35;
    border: 1px solid @riba-stone-blue;

    color: @riba-slate;
}

//== Elements
//

//== Modifiers
//

.singleline-text-input--full-width {
    width: 100%;
}

.singleline-text-input--error {
    border: 1px solid @error-primary;

    outline: none;
}

