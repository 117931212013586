@import '../themes/default/colours.less';
@import '../themes/default/config.less';

// Molecule: "Featured Content Item"
//

//== Variables
//

//== Mixins
//

//== Block
//

.featured-items {
    display: flex;
    flex-wrap: wrap;

    background-color: @riba-white;

    justify-content: space-between;
}

.featured-item {
    float: left;

    width: 100%;
    margin-bottom: 0.25em;

    background-color: @riba-light-grey;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: @screen-sm-min) {
        flex: 1 1 33%;
        width: 33%;
        margin: 6px .25% 0 0;

        &:nth-of-type(3n) {
            margin-right: 0;
        }
    }
}

//== Elements
//

.featured-item__image {
    float: left;
    width: 35%;
    padding: 0.2em 0.625em 0 0.625em;

    @media (min-width: @screen-sm-min) {
        float: none;
        padding: 0;

        width: 100%;
        max-width: 100%;
    }
}

.featured-item__content {
    padding: 0 1em;

    overflow: hidden;

    @media (min-width: @screen-sm-min) {
        padding: 1.5em 1.25em .5em;
        min-height: 250px;
    }

    @media (min-width: @screen-md-min) {
        min-height: 200px;
    }

    .flexbox & {
        height: auto;
    }
}

.featured-item__title {
    margin: 0;

    font-size: 1em;
    color: @riba-slate;
    text-transform: uppercase;
    overflow: hidden;

    @media (min-width: @screen-xs-min) {
        max-height: none;
        font-size: 1em;
    }

    @media (min-width: @screen-sm-min) {
        font-size: @font-size-h2;
        margin-bottom: .83em;
    }
}

.featured-item__synopsis {
    margin-top: 0;
    margin-bottom: 1em;

    font-size: 0.875em;
    font-weight: normal;
    line-height: 1.44em;

    color: @riba-slate;

    @media (min-width: @screen-sm-min) {
        font-size: 1.125em;
    }
}

//== Modifiers
//
.featured-item--support-two-column {
    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: @screen-sm-min) {
        width: 49.5%;
        margin-right: .8%;
        margin-top: 6px;
        margin-bottom: 0;

        &:nth-of-type(3n) {
            margin-right: .8%;
        }

        &:nth-of-type(2n) {
            margin-right: 0;
        }
    }

    @media (min-width: @screen-md-min) {
        flex: 1 1 33%;
        width: 33%;
        margin-right: .4%;

        &:nth-of-type(2n) {
            flex: 1 1 33%;
            margin-right: .4%;
        }

        &:nth-of-type(3n) {
            flex: 1 1 33%;
            margin-right: 0;
        }
    }
}

//== Defaults
//
