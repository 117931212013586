@import '../themes/default/colours.less';
@import '../themes/default/config.less';

// Organism: "Call To Action Band"
//
// A call to action type organism which prompts and promotes visitors of the site to click through to
// whichever link(s) are promoted in the organism.

//== Variables
//

//== Mixins
//

//== Block
//
.cta-band {
    width: 100%;

    background-color: @riba-white;
    text-align: center;

    padding: 2.2em 0 2em;

    @media (min-width: @screen-md-min) {
        padding-bottom: 3.125em;
    }
}

//== Elements
//

.cta-band__title {
    margin: 0 auto 16px;

    text-transform: uppercase;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: @riba-slate;

    @media (min-width: @screen-md-min) {
        font-size: 35px;
        line-height: 42px;
        margin-bottom: 16px;
    }
}

.cta-band__introduction {
    margin: 0 auto 28px;
    width: 90%;

    font-size: 16px;
    line-height: 25px;
    color: @riba-slate;

    @media (min-width: @screen-md-min) {
        width: 60%;
    }
}

.cta-band__buttons {
    width: 100%;

    .button {
        margin: 0 10px 16px;

        text-align: center;
        vertical-align: middle;

        @media (min-width: @screen-md-min) {
            margin-bottom: 0;
        }
    }
}

//== Modifiers
//

.cta-band--medium {
    background: @riba-light-stone-blue;
}

.cta-band--dark {
    background: @riba-slate;
    color: white;

    .cta-band__title,
    .cta-band__introduction {
        color: white;
    }

    .button {
        color: white;
        border-color: @riba-light-stone-blue;
    }

    .button--opaque {
        color: @riba-slate;
        background-color: @riba-light-stone-blue;
    }
}
