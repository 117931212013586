// Organism: "Hero Banner"
//

//== Variables
//

//== Mixins
//

//== Block
//

// The hero banner container uses an image as as a background on larger screens
.hero-banner {
    .content-container {
        padding: 0;
    }

    @media (min-width: @screen-md-min) {
        height: 23.75em;
        position: relative;
        width: 100%;

        background-color: @riba-white;
        background-position: center;
        background-size: cover;
    }

    @media (max-width: @screen-sm-max) {
        background-image: none !important;
    }
}

//== Elements
//

// The hero banner image is only shown on smaller screens in place of hero-banner above
.hero-banner__image {
    width: 100%;

    @media (min-width: @screen-md-min) {
        display: none;
    }
}

.hero-banner__content-container {
    @media (min-width: @screen-md-min) {
        height: 23.75em;
        padding: .667em 1em;
    }
}

.hero-banner__content {
    background-color: @riba-stone-blue;

    // Center align vertically on larger screens
    @media (min-width: @screen-md-min) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        background-color: transparent;
    }
}

.hero-banner__title {
    padding: .7em .667em 0 .667em;
    margin: 0;

    text-transform: uppercase;
    font-size: 1.875em;
    line-height: 1.16;

    // Restrict width on larger screens
    @media (min-width: @screen-md-min) {
         width: 10.244em;
         padding: 0;
         margin-top: 0.2em;

         font-size: 2.813em;
    }
}

.hero-banner__title-link {
    color: @riba-slate;

    &:hover,
    &:active,
    &:focus {
        color: @riba-slate;
    }

    @media (min-width: @screen-md-min) {
        color: @riba-white;

        &:hover,
        &:active,
        &:focus {
            color: @riba-white;
        }
    }
}

.hero-banner__text {
    padding: 0 1.111em;
    margin: 1em 0;

    font-weight: bold;
    font-size: 1.125em;
    color: @riba-slate;
    line-height: 1.39;

    @media (min-width: @screen-md-min) {
        width: 60%;
        padding: 0;

        color: @riba-white;
        font-size: 1.125em;
    }
}

.hero-banner__button-container {
    padding: 0 1.25em 1.25em 1.25em;

    text-align: center;

    @media (min-width: @screen-md-min) {
        padding: 0.5em 0;

        text-align: left;
    }
}

// Custom button styles due to hero banner specific differences
.hero-banner__button {
    width: 100%;
    padding: .5632em;
    margin-top: .375em;

    border: 0;
    background-color: @riba-slate;
    color: @riba-white;
    font-weight: bold;

    &:hover {
        color: @riba-white;
    }

    // Fixed with buttons on larger screens (but allow scope for larger text)
    @media (min-width: @screen-md-min) {
        width: auto;
        padding: .5632em 1.25em;

        background-color: #ecf0f3;
        border: 0;
        color: @riba-slate;
        font-weight: bold;

        &:hover {
            color: @riba-slate;
        }
    }
}

//== Modifiers
//

//== Defaults
//
