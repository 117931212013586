//
// Styles for join-riba-form-title organism
// =========================================

//== Mixins
//

//== Block
//

.riba-form-title {
    text-transform: uppercase;
    line-height: 1.16;
}

//== Elements
//

//== Modifiers
//
