@import '../mixins/utilities.less';
//
// Styles for dropdown-input atom
// =========================================

//== Mixins
//

//== Block
//

.dropdown-input {
    .appearance(none);
    border-radius: 0;
    padding: 8px 50px 8px 20px;
    line-height: 1.35;
    border: 1px solid @riba-stone-blue;

    color: @riba-slate;

    background: url('/images/dropdown-input/ic_keyboard_arrow_down_black_24px.svg') no-repeat;
    background-color: @riba-white;
    background-position: right 10px center;

    @media(max-width: @screen-sm-min) {
        width: 100%;
    }

    &::-ms-expand {
        display: none;
    }
}

//== Elements
//

//== Modifiers
//

.dropdown-input--error {
    border: 1px solid @error-primary;
    outline: none;
}

.dropdown-input--full-width {
    width: 100%;
}
