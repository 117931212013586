//
// Styles for form-price-overview molecule
// =========================================

//== Mixins
//

//== Block
//

.form-price-overview {
    padding: 34px 40px;
    width: 100%;
    margin: 32px auto;

    background-color: @riba-light-stone-blue;

    font-size: 22px;

    @media(max-width: @screen-sm-min) {
        font-size: 18px;
        padding: 28px 8px;
    }

    &.js-toggle-hidden {
        display: none;
    }
}

//== Elements
//

.form-price-overview__title {
    margin: 0;
    font-size: 26px;
    text-transform: uppercase;

    @media(max-width: @screen-sm-min) {
        font-size: 20px;
    }
}

.form-price-overview__date,
.form-price-overview__text {
    margin: 8px auto;
}

.form-price-overview__total {
    margin-right: 10%;
    display: inline-block;
}

.form-price-overview__divider {
    margin: 22px auto;
}

.form-price-overview__tooltip {
    display: inline-block;
    font-size: 22px;

    @media(max-width: @screen-sm-min) {
        font-size: 16px;
        margin-top: 22px;
        margin-bottom: 7px;
        width: 100%;
    }
}

//== Modifiers
//

.form-price-overview--small {
    .form-price-overview();
    font-size: 16px;

    .form-price-overview__title {
        font-size: 18px;
    }

    .form-price-overview--large {
        font-size: 18px;
    }

    .form-price-overview--xLarge {
        font-size: 26px;
    }
}

.form-price-overview--strong {
    font-weight: bold;
}

.form-price-overview--large {
    margin-right: 8px;
    font-size: 35px;

    @media(max-width: @screen-sm-min) {
        font-size: 26px;
    }
}

.form-price-overview--xLarge {
    margin-right: 8px;
    font-size: 45px;

    @media(max-width: @screen-sm-min) {
        font-size: 35px;
    }
}
