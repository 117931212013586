@import '../mixins/adaptive.less';

//
// Styles for join-riba-confirmation-content organism
// =========================================

//== Mixins
//

//== Block
//

//== Elements
//

.join-riba-confirmation-content__title {
    padding-bottom: 29px;
}

.join-riba-confirmation-content__section {
    margin-bottom: 112px;

    @media(max-width: @screen-sm-min) {
        margin-bottom: 40px;
    }
}

.join-riba-confirmation-content__button {
    display: inline-block;

    vertical-align: middle;
    width: 49%;
}

.join-riba-confirmation-content__bacs-logo,
.join-riba-confirmation-content__direct-debit-logo {
    display: inline-block;

    margin-left: 48px;
    vertical-align: middle;

    @media(max-width: @screen-sm-min) {
        margin-left: 36px;
    }

    @media(max-width: @screen-xs-min) {
        margin-left: 1px;
    }
}

.join-riba-confirmation-content__direct-debit-link {
    display: block;

    margin: 12px 0;
}

//== Modifiers
//
