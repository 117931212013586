//
// Styles for join-riba-paragraph atom
// =========================================

//== Mixins
//

//== Block
//

.join-riba-paragraph {
    font-size: 22px;
    margin: 0 0 18px;
}

//== Elements
//

//== Modifiers
//
