// Organism: "Resource content"
//
// Provides a list of key information.

//== Variables
//

//== Mixins
//

//== Block
//

.resource-content {
    padding-top: 30px;

    @media (min-width: @screen-sm-min) {
        padding-top: 60px;
        min-height: 560px;
    }
}

//== Elements
//

.resource-content__container {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;

    @media (max-width: @screen-xs-max) {
        flex-wrap: wrap;
    }
}

.resource-content__content {
    flex: 0 0 100%;

    @media (max-width: @screen-xs-max) {
        padding-bottom: 30px;
    }
}

.resource-content__restricted-panel {
    flex: 0 0 100%;
    padding-bottom: 54px;

    @media (min-width: @screen-sm-min) {
        flex: 0 0 335px;
        padding-bottom: 0;
    }
}

.resource-content__title {
    width: 100%;
}

.resource-content__date {
    font-size: 16px;
}

.resource-content__excerpt {
    font-size: 18px;
}

.resource-content__resource-button {
    margin-bottom: 60px;

    @media (max-width: @screen-xs-max) {
        margin-bottom: 40px;
        width: 100%;
    }
}

//== Modifiers
//

.resource-content__content--restricted {
    .resource-content__content();

    @media (max-width: @screen-xs-max) {
        padding-bottom: 42px;
    }

    @media (min-width: @screen-sm-min) {
       flex: 0 1 460px;
    }
}

//== Defaults
//
