// Organism: "Membership Benefit Card Board"
//
// A board of membership benefits to be used across the site

//== Variables
//

//== Mixins
//

//== Block
//
.membership-benefit-card-board {
    padding-top: 2em;
    padding-bottom: 1em;

    background-color: @riba-white;
}

//== Elements
//
.membership-benefit-card-board__title {
    margin-top: 0;
    margin-bottom: 0;

    text-transform: uppercase;
    text-align: center;
    font-size: 1.563em;
    line-height: 1.2;
    color: @riba-slate;

    @media (min-width: @screen-sm-min) {
        margin-bottom: .25em;

        font-size: 2.188em;
    }
}

.membership-benefit-card-board__intro {
    margin-top: .5em;
    margin-bottom: 1.25em;

    text-align: center;
    font-size: 1.125em;
    line-height: 1.56;

    @media (min-width: @screen-sm-min) {
        max-width: 52.222em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1.5em;

        line-height: 1.41;
        font-size: 1.375em;
    }
}

.membership-benefit-card-board__cards {
    @media (min-width: @screen-sm-min) {
        display: flex;
        flex-wrap: wrap;
    }
}

//== Modifiers
//

//== Defaults
//
