@import '../mixins/utilities.less';
//
// Styles for datalist-dropdown atom
// =========================================

//== Mixins
//

//== Block
//

.datalist-dropdown {
    .appearance(none);
    border-radius: 0;
    padding: 8px 50px 8px 20px;
    line-height: 1.35;
    border: 1px solid @riba-stone-blue;

    color: @riba-slate;

    @media(max-width: @screen-sm-min) {
        width: 100%;
    }

    &:not(:disabled) {
        background: url('/images/dropdown-input/ic_keyboard_arrow_down_black_24px.svg') no-repeat;
        background-color: @riba-white;
        background-position: right 10px center;
    }

    &::-webkit-calendar-picker-indicator {
        display: none;
    }

    &:invalid {
        border: 1px solid @error-primary;
        outline: none;
    }

    &::-ms-expand {
        display: none;
    }
}

//== Elements
//

//== Modifiers
//

.datalist-dropdown--error {
    border: 1px solid @error-primary;
    outline: none;
}

.datalist-dropdown--full-width {
    width: 100%;

    &:not(:disabled) {
        background-position: right 10px center;
    }
}
