@import '../mixins/utilities.less';
//
// Styles for restricted-content-panel molecule
// =========================================

//== Mixins
//

//== Variables
//

@lock-icon-size: 17px;

//== Block
//

.restricted-content-panel {
    font-size: 16px;
    padding: 0;
    position: relative;
}

//== Elements
//

.restricted-content-panel__heading {
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;

    margin: 0 0 0 35px;
    padding-bottom: 8px;

    &:before {
        .material-icons();

        position: absolute;

        content: 'lock';
        color: @riba-cool-grey;

        font-size: @lock-icon-size;
        width: @lock-icon-size;
        height: @lock-icon-size;
        left: 0;
    }
}

.restricted-content-panel__paragraph {
    font-size: 16px;
    margin: 0 0 0 35px;

    @media (max-width: @screen-xs-max) {
        margin: 0;
    }
}

.restricted-content-panel__bullet-list {
    margin: 0 0 0 35px;

    > ul {
        font-size: 16px;

        margin: 0;
        padding-left: 30px;
        padding-bottom: 16px;

        > li {
            margin: 0;
            padding: 2px 0 2px 12px;
        }
    }
}

.restricted-content-panel__button {
    margin: 12px 0 12px 35px;

    @media (max-width: @screen-xs-max) {
        margin: 12px 0;
        width: 100%;
    }
}

//== Modifiers
//
