// Base: "Lazyload"
//

//== Variables
//

//== Mixins
//

//== Block
//

//== Elements
//
.lazyload__placeholder {
    width: 100%;

    background: #ddd;
}

.lazyload__placeholder--64 {
    padding-bottom: 64%;
}

.no-js .lazyload__placeholder {
    padding-bottom: 0;
}

.lazyload__image {
    display: none;
    width: 100%;
}

.no-js .lazyload__image {
    display: block;
    width: 100%;
}

//== Modifiers
//
.lazyload--image-loaded {
    .lazyload__placeholder {
        padding-bottom: 0;
    }

    .lazyload__image {
        display: block;
    }
}

//== Defaults
//
