// Molecule: "info-bar"
//

//== Variables
//

//== Mixins
//

//== Block
//

.info-bar {
    width: 100%;
    padding: 10px 0;

    background-color: @riba-slate-green;

    &.js-hide-bar {
        display: none;
    }
}

//== Elements
//

.info-bar__large-content-container {
    .large-content-container();

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info-bar__content {
    font-size: 14px;
    color: @riba-white;

    flex: 1 1 100%;

    a {
        color: white;
        text-decoration: underline;
    }
}

.info-bar__beta-label {
    display: inline-block;

    min-width: 40px;
    height: 20px;
    margin-right: 5px;
    padding: 0 4px;

    background-color: @riba-white;

    color: @riba-slate;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.info-bar__close {
    flex: 0 0 20px;

    a {
        font-weight: normal;
        color: @riba-white;
    }
}

.info-bar__close-icon {
    .material-icons();

    font-size: 20px;
    vertical-align: middle;
}

//== Modifiers
//
