//
// Styles for checkbox atom
// =========================================

//== Mixins
//

//== Variables
//

@checkbox-size: 22px;
@checkbox-font-size: @checkbox-size;
@label-left-margin: @checkbox-size + 10px;

//== Block
//

//== Elements
//

.checkbox__group {
    margin-bottom: 10px;
    display: block;
}

.checkbox__input {
    visibility: hidden;
    position: absolute;
}

.checkbox__check {
    width: @checkbox-size;
    height: @checkbox-size;
    float: left;

    &::after {
        .material-icons();

        position: absolute;
        font-size: @checkbox-font-size;

        color: @riba-stone-blue;
        content: 'check_box_outline_blank';
    }
}

.checkbox__text {
    margin: 0 0 0 @label-left-margin;
    vertical-align: top;
    font-weight: normal;
}

.checkbox__subtext {
    color: @riba-light-slate;
}

//== Modifiers
//

.checkbox__input:checked ~ .checkbox__check:after {
    color: @riba-blue;
    content: 'check_box';
}

//
// Checkbox
// =========================================

//== Mixins
//

//== Block
//

//== Elements

.checkbox-placeholder label {
    font-weight: normal;
}
.checkbox-placeholder input[type="checkbox"]:empty {
    display: none;
}
.checkbox-placeholder input[type="checkbox"]:empty ~ label {
    position: relative;
    line-height: 1.2em;
    text-indent: 2.25em;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
		padding: 2px;
}
.checkbox-placeholder input[type="checkbox"]:empty ~ label:before {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    content:'';
    width: 1.5em;
    background: none;
		border: 2px solid #c1d0d7;
    border-radius: 3px;
}
.checkbox-placeholder input[type="checkbox"]:hover:not(:checked) ~ label:before {
    content:'\2714';
		text-indent: .27em;
    color: #C2C2C2;
}

.checkbox-placeholder input[type="checkbox"]:checked ~ label:before {
    content:'\2714';
    text-indent: .27em;
    color: #fff;
    background-color: #407ec9;
		border: 2px solid #407ec9;
}
.checkbox-placeholder input[type="checkbox"]:checked ~ label {
    color: @riba-slate;
}


//

//== Modifiers
//
