//
// Styles for contact-riba molecule
// =========================================

//== Mixins
//

//== Block
//

.contact-riba {
    width: 100%;
    display: block;
    padding: 0.5em 2em;

    background-color: @riba-light-stone-blue;
}

//== Elements
//

.contact-riba__info-wrapper {
    display: block;
    margin-bottom: 1em;
}

.contact-riba__info {
	display: inline-block;
    vertical-align: text-top;
}

.contact-riba__info-label {
    width: 15%;
    min-width: 6em;
    display: inline-block;

    @media(max-width: @screen-sm-min) {
        width: 100%;
    }
}

.contact-riba__info-main {
    font-weight: bold;
    font-size: 1em;
}

.contact-riba__info-sub {
    font-size: .75em
}

.contact-riba .material-icons {
    line-height: 1.56;
    vertical-align: middle;
    margin-right: 4px;
    font-size: 16px;
}

//== Modifiers
//
