@import '../themes/default/colours.less';
@import '../themes/default/config.less';

// Molecule: "Footer Organisation Details"
//

//== Variables
//

//== Mixins
//

//== Block
//

.organisation-details {
    padding: 0;
    margin: 0 0 12px;

    font-size: 12px;
    color: @riba-stone-blue;

    a {
        .link-variant(@riba-stone-blue, @riba-stone-blue, none);
    }

    @media (min-width: @screen-md-min) {
        display: inline-block;
    }
}

//== Elements
//

.organisation-details__separator {
    color: @riba-stone-blue;
    padding: 0 6px;
}

//== Defaults
//
