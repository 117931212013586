//
// Styles for input-wrapper molecule
// =========================================

//== Mixins
//

//== Block
//

.input-wrapper {

}

//== Elements
//

.input-wrapper__tip {
    position: relative;

    margin-top: 5px;

    color: @riba-light-slate;
}

//== Modifiers
//

.validation-summary-errors {
    position: relative;

    margin-top: 5px;

    color: @error-primary;

    ul {
        padding-left: 0;
        margin: 0;

        list-style: none;
    }
}
