//
// Styles for step-description atom
// =========================================

//== Mixins
//

//== Block
//

.step-description {
    width: 100%;
    height: 2.75em;

    text-align: center;
}

//== Elements
//

.step-description__title {
    line-height: 1.38;

    margin: 0;

    color: @riba-slate;

    font-size: 16px;

    @media(max-width: @screen-sm-min) {
        display: none;
    }
}

.step-description__content {
    line-height: 1.57;

    color: @riba-cool-grey;

    font-size: 14px;

    @media(max-width: @screen-sm-min) {
        display: none;
    }
}


//== Modifiers
//

.step-description--current {
    .step-description__title {
        color: @riba-black;
        font-weight: bold;
    }
}
