//
// Styles for form-step-item molecule
// =========================================

//== Mixins
//

//== Block
//

.form-step-item {
    display: block;
}

//== Elements
//

//== Modifiers
//

