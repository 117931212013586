//== Mixins
//

//== Block
//

.primary-nav-second-level-item {
    list-style: none;
    border-top: 1px solid @nav-divider;

    @media (min-width: @screen-lg-min) {
        border: none;
    }

    a {
        color: @riba-white;
    }
}

//== Elements
//

.primary-nav-second-level-item__title {
    display: inline-block; // required because of the column-count

    width: 100%;
    padding: 16px;

    font-weight: bold;
    text-decoration: none;

    @media (max-width: @screen-md-max) {
        .primary-nav-second-level-item--active & {
            background-color: lighten(@riba-slate, 20%);
        }
    }

    @media (min-width: @screen-lg-min) {
        margin: 2px 0;
        padding: 8px 12px;

        font-size: 14px;
        font-weight: bold;
        line-height: 1;

        &:hover {
            background-color: lighten(@riba-slate, 10%);
        }

        .primary-nav-second-level-item--active & {
            background-color: lighten(@riba-slate, 20%);
        }
    }

    @media (min-width: 1440px) {
        font-size: 16px;
    }
}

//== Modifiers
//

//== Defaults
//
