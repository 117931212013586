// Molecule: "Login popup"
//

//== Variables
//

//== Mixins
//

//== Block
//

.login-content {
    padding: 35px 20px 25px 20px;
    max-width: 380px;
    background: @riba-white;
    text-align: left;
    position: relative;

    .form-input-container {
        margin-top: 16px;
        margin-bottom: 0;

        &__label--label-above {
            margin-bottom: 15px;
        }
    }
}

//== Elements
//

.login-content__close {
    .material-icons();

    position: absolute;
    top: 14px;
    right: 16px;

    color: @riba-slate;
    font-size: 24px;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus,
    &:visited {
        font-weight: normal;
        color: @riba-slate;
        text-decoration: none;
    }
}

.login-content__title {
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 4px;
    text-transform: uppercase;
}

.login-content__subtext {
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 0;
}

.login-content__forgot-password {
    display: block;
    margin-top: 3px;
    margin-bottom: 20px;
}

.login-content__login-button {
    width: 100%;
    margin-bottom: 28px;
}

.login-content__not-a-member {
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    margin: 9px 0;
}

//== Modifiers
//

//== Defaults
//
