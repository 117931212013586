//
// Styles for step-circle atom
// =========================================

//== Mixins
//

//== Block
//

.step-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: auto auto 7px;

    background-color: @riba-stone-blue;
}

//== Elements
//

.step-circle__content {
    line-height: 1.7;

    font-size: 1.125em;
    font-weight: bold;
    text-align: center;

    color: @riba-white;
}

//== Modifiers
//

.step-circle--current {
    background-color: @riba-blue;
}

.step-circle--complete {
    background-color: @riba-green;

    .step-circle__content {
        display: block;

        cursor: pointer;

        &:hover, &:active, &:focus {
            color: @riba-white;
        }
    }
}
