// Organism: "Need Help"
//

//== Variables
//

//== Mixins
//

//== Block
//
.need-help {
    margin-bottom: 1.5em;
    padding-top: 2em;

    text-align: center;
}

//== Elements
//

.need-help__heading {
    .heading-two__heading();

    margin: 0 0 .7em 0;
}

.need-help__contact-details {
    @media(min-width: @screen-md-min) {
        margin-top: 2em;
    }
}

.need-help__contact-detail {
    display: block;
    position: relative;
    margin: .5em 0;

    @media(min-width: @screen-md-min) {
        display: inline-block;
        margin: 0 .75em;
    }
}

.need-help__contact-detail-icon {
    position: absolute;
    top: .25em;

    color: @riba-slate;
    font-size: 1.063em;
}

.need-help__contact-detail-link {
    padding-left: 1.375em;
}

//== Modifiers
//

//== Defaults
//
