@import '../mixins/adaptive.less';

//
// Styles for bullet-list atom
// =========================================

//== Mixins
//

//== Variables
//
@bullet-list-font-size: 22px;

//== Block
//

.bullet-list {
    display: block;

    ul {
        font-size: @bullet-list-font-size;
        padding-right: 22px;

        @media (max-width: @screen-sm-min) {
            padding-left: 16px;
        }
    }

    li {
        margin-left: 12px;
        margin-bottom: 16px;
    }
}

//== Elements
//

//== Modifiers
//

.bullet-list--collapsibleOnXSmall {
    &.js-toggle-hidden {
        .hideOn(max; @screen-sm-min);
    }
}

.bullet-list--collapsible {
    &.js-toggle-hidden {
        display: none;
    }
}
