//
// Styles for join-riba-form-heading organism
// =========================================

//== Mixins
//

//== Block
//

.riba-form-heading {
    margin: 3em auto;

    @media(max-width: @screen-sm-min) {
        margin: 0;
    }
}

//== Elements
//

.riba-form-heading__title {
    text-transform: uppercase;
}

.riba-form-heading__steps {
    @media(max-width: @screen-sm-min) {
        margin: 0;
    }
}

.riba-form-heading__help {
    display: none;
    margin-bottom: 3em;

    @media(max-width: @screen-sm-min) {
        display: block;
    }
}

//== Modifiers
//
