@import '../themes/default/colours.less';

//
// Edit Button
// =========================================

//== Mixins
//

//== Variables
//

@edit-button-icon-size: 18px;
@edit-button-font-size: 16px;

//== Block
//

.edit-button {
    font-size: @edit-button-font-size;
    color: @riba-blue;

    &:before {
        .material-icons();

        font-size: @edit-button-icon-size;

        color: @riba-blue;
        content: 'mode_edit';

        vertical-align: middle;
        padding-right: 5px;
    }
}

//== Elements
//

//== Modifiers
//
