// Organism: "UMP Dashboard Listing"
//

//== Block
//

.dashboard-item-placholder {
  display: -webkit-flex; /* Safari */
  display: flex;
  -webkit-flex-direction: row; /* Safari */
  flex-direction: row;
  -webkit-flex-wrap: wrap; /* Safari */
  flex-wrap: wrap;
  margin-bottom: 2.5em;
}

.dashboard-item {
  flex-flow: 1;
  width: 30%;
  margin: 0 1.5em 1.5em;
  margin-bottom: 1.5em;
  margin-top: 0.5em;
  background: @riba-white;
  padding: 0 1.5em 1.5em;
  -webkit-box-shadow: 0 1px 0 0 #ddd;
  box-shadow: 0 1px 0 0 #ddd;
  @media (max-width: @screen-md-min) {
      width: 100%;;
  }
}

.dashboard-icons {
  font-size: 3.5em;
  color: @riba-slate;
  float: left;
  width: 13%;
}

//== Elements

.dashboard-item__title {
  color: @riba-slate;
  font-size: 2em;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.3em;
}

.dashboard-item__anchor {
  color: #407ec9;
  font-size: 2em;
  font-weight: 400 !important;
  text-decoration: underline;
    @media (max-width: @screen-sm-max) {
      font-size: 1.5em;
    }

  &:hover {
      text-decoration: none;
    }
}

.dashboard-item__info {
  color: #999fa3;
}


//
