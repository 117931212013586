// Organism: "Event Content"
//
// A container for event content.

//== Variables
//

//== Mixins
//

//== Block
//
.event-content {
    margin-top: 2.75em;
}

//== Elements
//


//== Modifiers
//

//== Defaults
//
