// Molecule: "Promoted Link Item"
//

//== Variables
//

//== Mixins
//

//== Block
//
.promoted-link {
    width: 100%;
}

//== Elements
//

.promoted-link__image {
    width: 100%;
    display: block;
}

.promoted-link__content {
    padding: 0 1em 1.875em;

    text-align: center;

    @media (min-width: @screen-lg-min) {
        padding: 0 1em 2.85em;
    }
}

.promoted-link__title,
.promoted-link__synopsis,
.promoted-link__button {
    color: @riba-white;
    text-align: center;
}

.promoted-link__title {
    margin-top: 0;
    margin-bottom: 0.5em;
    padding-top: 1em;

    font-size: 1.563em;
    text-transform: uppercase;

    @media (min-width: @screen-sm-min) {
        font-size: 1.25em;
    }

    @media (min-width: @screen-md-min) {
        font-size: 1.563em;
    }

     @media (min-width: @screen-lg-min) {
        font-size: 2.188em;
    }
}

.promoted-link__synopsis {
    margin: 0;

    font-size: 1em;

    @media (min-width: @screen-sm-min) {
        min-height: 3em;

        font-size: 1em;
    }

    @media (min-width: @screen-lg-min) {
        min-height: 3em;

        font-size: 1.125em;
    }
}

.promoted-link__button {
    margin-top: 1.8em;
    border-color: @riba-white;
    width: auto;
    min-width: 15.625em;
    padding-left: 1.563em;
    padding-right: 1.563em;

    &:hover,
    &:focus,
    &:active {
        color: @riba-white;
    }
}

//== Modifiers
//
.promoted-link__content--orange {
    background: @riba-orange;
}

.promoted-link__content--rose {
    background: @riba-rose;
}

.promoted-link__content--slate-green {
    background: @riba-slate-green;
}

.promoted-link__content--taupe {
    background: @riba-taupe;
}

.promoted-link__content--beige {
    background: @riba-beige;
}

.promoted-link__content--blue {
    background: @riba-blue;
}

.promoted-link__content--bright-blue {
    background: @riba-bright-blue;
}

.promoted-link__content--green {
    background: @riba-green;
}

.promoted-link__content--yellow {
    background: @riba-yellow;
}

.promoted-link__content--medium-pink {
    background: @riba-medium-pink;
}

.promoted-link__content--lime-green {
    background: @riba-lime-green;
}

//== Defaults
//
