// Molecule: "Card"
//

//== Variables
//
@content-horizontal-padding: 1.25em;

//== Mixins
//

//== Block
//

.card {
    display: block;
    position: relative;
    margin-bottom: 17px; // http://lists.w3.org/Archives/Public/www-style/2014May/0015.html

    background-color: @riba-light-grey;

    @media (min-width: @screen-md-min) {
        flex: 1 1 30.3%;
        width: 30.3%;
        margin-right: 1.5%;
        min-height: 32em;

        &:nth-of-type(3n) {
            margin-right: 0;
        }
    }
}

//== Elements
//
.card__image {
    width: 100%;
}

.card__content {
    padding-left: @content-horizontal-padding;
    padding-right: @content-horizontal-padding;
    padding-bottom: 2.5em;
}

.card__type {
    display: block;
    padding: 1.313em 0;

    text-transform: uppercase;
    letter-spacing: .169em;
    font-size: .75em;
    font-weight: bold;

    @media (min-width: @screen-md-min) {
        padding: 1.938em 0 1.313em;
    }
}

.card__title {
    margin-top: .125em;

    color: @riba-slate;
    text-transform: uppercase;
    font-size: 1.125em;
    line-height: 1.39;
}

.card__synopsis {
    margin: 1em 0;

    color: @riba-slate;
    font-size: 1em;
    font-weight: normal;
    line-height: 1.56;
}

//== Modifiers
.card--resource {
    min-height: 240px;
}

.card__type--competition {
    color: @competition;
}

.card__type--award {
    color: @award;
}

.card__type--resource {
    color: @riba-green;
}

//== Defaults
//
