//
// Typography
// =========================================

h1 {
    margin: 21px 0 20px;

    font-size: 30px;
    color: @riba-slate;

    @media (min-width: @screen-sm-min) {
        margin-top: 0;
        font-size: 45px;
    }
}

p {
    color: @riba-slate;
}
