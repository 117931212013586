// Organism: "Header"
//
// The main RIBA site header

//== Block
//

.header {
    .clearfix();

    width: 100%;
    min-height: 3.75em;

    background: @riba-light-stone-blue;

}

//== Elements
//

.header__logo-container {
    float: left;
    width: ~"calc(100% - 13em)";

    @media (min-width: @screen-md-min) {
        width: 8em;
    }
}

.header__primary-nav-container {
    float: right;
    width: 13em;

    @media (min-width: @screen-md-min) {
        width: ~"calc(100% - 8em)";
        float: right;
    }

    @media (min-width: @screen-lg-min) {
        width: auto;
        float: right;
    }
}

//== Modifiers
//
