// Molecule: "Membership Type Card"
//

//== Variables
//

//== Mixins
//

//== Block
//

.membership-type-card {
    .clearfix();
    display: block;
    position: relative;
    margin-bottom: .125em;
    padding: .625em;

    background-color: @riba-light-grey;

    @media (min-width: @screen-sm-min) {
        padding: 0;
        margin-bottom: 1.5%;
    }

    // Rows of three on medium-large screens
    @media (min-width: @screen-md-min) {
        flex: 1 1 auto;
        width: 32.3%;
        margin-right: 1.5%;
        padding: 0;
        min-height: 26em;

        // Remove margin from every third item
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}

//== Elements
//
.membership-type-card__image {
    float: left;
    width: 6.5em;
    margin: .125em .625em .125em .125em;

    @media (min-width: @screen-sm-min) {
        width: 100%;
        margin: 0;
    }
}

.membership-type-card__content {
    float: left;
    width: ~'calc(100% - 7.79em)'; // Allows for membership-type-card__image

    @media (min-width: @screen-sm-min) {
        width: 100%;
        padding: 0 1.25em;
    }
}

.membership-type-card__title {
    margin: 0;

    color: @riba-slate;
    text-transform: uppercase;
    font-size: 1em;
    line-height: 1.25;

    @media (min-width: @screen-sm-min) {
        margin: 1em 0 .5em 0;

        font-size: 1.625em;
    }
}

.membership-type-card__synopsis {
    margin: 0;

    color: @riba-slate;
    font-size: .875em;
    font-weight: normal;
    line-height: 1.56;

    @media (min-width: @screen-sm-min) {
        font-size: 1.125em;
        padding-bottom: 1em;
    }
}

//== Defaults
//
