//
// Styles for form-input-container molecule
// =========================================

//== Mixins
//

//== Block
//

.form-input-container {
    margin: 24px auto;
}

//== Elements
//

.form-input-container__label {
    display: inline-block;
    vertical-align: top;
    width: 49%;

    @media(max-width: @screen-sm-min) {
        margin-bottom: 8px;
        width: 100%;
    }
}

.form-input-container__input {
    display: inline-block;
    vertical-align: top;
    width: 49%;

    @media(max-width: @screen-sm-min) {
        width: 100%;
    }
}

//== Modifiers
//

.form-input-container__label--label-above {
    .form-input-container__label();

    display: block;
    margin-bottom: 10px;
    width: 100%;
}

.form-input-container__label--narrow-label {
    .form-input-container__label();

    width: 39%;
}

.form-input-container__input--label-above {
    .form-input-container__input();

    display: block;
    width: 100%;
}

.form-input-container__input--narrow-label {
    .form-input-container__input();

    width: 59%;
}
