// Molecule: "Competition List Item"
//
// This is a fairly complex component with four different states depending on
// screen size.
//
// Extra Small Screens
// |   image    |
// |   status   |
// |   heading  |
// |   type     |
//
//
// Small Screens
// |       | status  |
// | image | heading |
// |       | type    |
//
//
// Medium and Large Screens
// |             | status    |
// | large image | heading   |
// |             | synopsis  |
// |             | type      |
//
//
// Extra Large Screens
// |             | status    |            |
// | large image | heading   |  details   |
// |             | synopsis  |  cta       |
// |             | type      |            |
//
//== Variables
//
@image-width-sm: 9.375em; // 150px @ 16px/1em
@image-width-md: 21.938em; // 351px @ 16px/1em
@main-width-xl: 60em; // 960px @ 16px/1em

//== Mixins
//

//== Block
//
.competition-list-item {
    .clearfix();

    padding: .25em 0;

    background: @riba-white;

    @media (min-width: @screen-sm-min) {
        padding: .75em 0;
    }

    @media(min-width: @screen-md-min) {
        padding: 1.25em 0;
        border-bottom: solid 1px @riba-light-stone-blue;

        &:last-child {
            padding-top: none;
        }
    }
}

//== Elements
//
.competition-list-item__main-container {
    .clearfix();

    display: block;

    @media (min-width: @screen-xl-min) {
        float: left;
        width: @main-width-xl;
    }
}

// Floats left on small screens and above
.competition-list-item__image {
    width: 100%;

    @media (min-width: @screen-sm-min) {
        float: left;

        width: @image-width-sm;
    }

    @media (min-width: @screen-md-min) {
        width: @image-width-md;
    }
}

// Floats left (next to .competition-list-item__image) on small screens and above
.competition-list-item__content {
    padding: 1em 1.25em;

    background: @riba-light-grey;

    @media (min-width: @screen-sm-min) {
        float: left;
        padding: 0 1.25em;
        width: calc(100% ~'-' @image-width-sm + 2.5em); // 100% - (image width + left/right padding)

        background-color: transparent;
    }

    @media (min-width: @screen-md-min) {
        width: calc(100% ~'-' @image-width-md + 2.5em); // 100% - (image width + left/right padding)
    }
}

.competition-list-item__label,
.competition-list-item__divider,
.competition-list-item__category  {
    display: inline-block;

    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: .75em;
    color: @riba-cool-grey;
}

.competition-list-item__label {
    color: @competition;
}

.competition-list-item__divider {
    padding: 0 1em;
}

// Sets minimum height on text content (heading and synopsis) to match image height
.competition-list-item__content-text {
    min-height: 2em;

    @media (min-width: @screen-sm-min) {
        min-height: 3em;
    }

    @media (min-width: @screen-md-min) {
        min-height: 10.5em;
    }
}

.competition-list-item__heading {
    margin-top: .75em;

    color: @riba-slate;
    font-size: 1.125em;
    text-transform: uppercase;

    @media (min-width: @screen-sm-min) {
        margin-top: .25em;
        margin-bottom: 0;

        font-size: 1.25em;
    }

    @media (min-width: @screen-md-min) {
        margin-top: .423em;

        font-size: 1.5em;
    }

    @media (min-width: @screen-lg-min) {
        font-size: 1.625em;
    }
}

// Only show synopsis on medium screens and above
.competition-list-item__synopsis {
    display: none;

    @media (min-width: @screen-md-min) {
        display: block;
        margin-top: .75em;
        margin-bottom: .5em;

        font-size: 1.125em;
        font-weight: normal;

        color: @riba-slate;
    }
}

// This overrides styles in the existing competition-type molecule
.competition-list-item__type {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    font-weight: normal;

    @media (min-width: @screen-sm-min) {
        margin-top: .25em;
    }

    @media (min-width: @screen-md-min) {
        margin-top: 0;
    }
}

// Only shown on extra large screens, floats next to .competition-list-item__main-container
.competition-list-item__details-container {
    display: none;

    @media (min-width: @screen-xl-min) {
        .clearfix();

        display: block;
        float: left;
        width: calc(100% ~'-' @main-width-xl + 2em);
        padding: 0 1em;
    }
}

.competition-list-item__details-content {
    float: right;
    width: 17.5em; // 280px @ 16px/1em
    margin-right: 10px;
}

.competition-list-item__list {
    min-height: 8.625em;
    margin: 1.562em 0 1.5em 0;
    position: relative;

    list-style: none;
    padding: 0;
}

.competition-list-item__list-item {
    padding: .375em 0;
    color: @riba-slate;
}

.competition-list-item__list-text {
    display: inline-block;
    width: ~'calc(100% - 2em)'; // allow for .competition-list-item__list-icon
    padding-left: 1em;
}

.competition-list-item__list-icon {
    vertical-align: top;
    margin-left: -.125em; // Fix inconsistency between icon font and prize-icon svg

    font-size: 1.125em;
    color: @riba-cool-grey;
}

.competition-list-item__cta {
    width: 15.625em; // 250px @16p/1em
}

//== Defaults
//
