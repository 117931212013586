@font-face {
    font-family: "Bariol";
    font-style: normal;
    font-weight: normal;
    src: url("../../fonts/bariol_regular-webfont.eot"); /* IE9 Compatibility Modes Support */
    src: url("../../fonts/bariol_regular-webfont.eot?#iefix") format("embedded-opentype"), /* IE6 - IE8 Support */
         url("../../fonts/bariol_regular-webfont.woff") format("woff"),  /* Modern Browsers */
         url("../../fonts/bariol_regular-webfont.ttf") format("truetype"), /* Safari, Android, iOS Support */
         url("../../fonts/bariol_regular-webfont.svg#Bariol") format("svg"); /* Legacy iOS Support */
}

@font-face {
    font-family: "Bariol";
    font-style: normal;
    font-weight: bold;
    src: url("../../fonts/bariol_bold-webfont.eot"); /* IE9 Compatibility Modes Support */
    src: url("../../fonts/bariol_bold-webfont.eot?#iefix") format("embedded-opentype"), /* IE6 - IE8 Support */
         url("../../fonts/bariol_bold-webfont.woff") format("woff"),  /* Modern Browsers */
         url("../../fonts/bariol_bold-webfont.ttf") format("truetype"), /* Safari, Android, iOS Support */
         url("../../fonts/bariol_bold-webfont.svg#Bariol") format("svg"); /* Legacy iOS Support */
}

@font-face {
    font-family: "Bariol";
    font-style: italic;
    font-weight: normal;
    src: url("../../fonts/bariol_light-webfont.eot"); /* IE9 Compatibility Modes Support */
    src: url("../../fonts/bariol_light-webfont.eot?#iefix") format("embedded-opentype"), /* IE6 - IE8 Support */
         url("../../fonts/bariol_light-webfont.woff") format("woff"),  /* Modern Browsers */
         url("../../fonts/bariol_light-webfont.ttf") format("truetype"), /* Safari, Android, iOS Support */
         url("../../fonts/bariol_light-webfont.svg#Bariol") format("svg"); /* Legacy iOS Support */
}
