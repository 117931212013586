//
// Links
// =========================================

//== Mixins
//

//== Block

.heading-one {

}

//

//== Elements

.heading-one__heading {
    margin-bottom: 0.2em;
}

.heading-one__subheading {
    color: @riba-green;
    display: block;
    font-size: 1.2em;
    margin-top: 0;
}

//

//== Modifiers
//

//== Defaults
//
