@import '../themes/default/colours.less';

//
// Button
// =========================================

//== Mixins
//

//== Block
//

.logo {
    box-sizing: content-box;
    display: block;
    width: 110px;
    height: 43px;

    color: @riba-slate;
}

//== Elements
//

//== Modifiers
//

.logo--header {
    padding: .5em .625em .5em 0;

    @media (min-width: @screen-md-max) {
        padding-top: 1.125em;
        padding-bottom: 1.125em;
    }

    @media (min-width: 1440px) {
        padding-top: 1.563em;
        padding-bottom: 1.563em;
    }
}
