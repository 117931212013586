//== Block
//

.ump-user-details {
    background-color: @riba-slate;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 2em;
}

//== Elements

.ump-user-details__title {
    color: @riba-white;
    font-size: 1.563em;
    margin: 0;
}

.ump-user-details__membership-details {
    color: @riba-white;
    margin: 0;
    list-style: none;
    padding: 0;
}

//

//== Modifiers
