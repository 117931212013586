@import './colours.less';

/**
 * Overwriting Bootstrap Variables for unique customisation.
 */

// == Font
//

@font-family-normal: "Bariol", "Helvetica", Verdana , sans-serif;
@font-family-sans-serif:  @font-family-normal;
@headings-font-family: @font-family-normal;

@font-size-base:          16px;
@font-size-small:         ceil((@font-size-base * 0.85)); // 13px
@font-size-large:         ceil((@font-size-base * 1.25)); // 20px
@font-size-intro:         ceil((@font-size-base * 1.4)); // 22px
@font-size-x-large:       ceil((@font-size-base * 1.95)); // 31px
@font-size-xx-large:      ceil((@font-size-base * 2.95)); // 47px
@font-size-super:         ceil((@font-size-base * 4)); // 64px

@font-size-h1:            floor((@font-size-base * 2));  // 32px
@font-size-h2:            floor((@font-size-base * 1.625)); // 28px
@font-size-h3:            ceil((@font-size-base * 1.5)); // 24px
@font-size-h4:            ceil((@font-size-base * 1.25)); // 20px
@font-size-h5:            @font-size-base; // 16px
@font-size-h6:            ceil((@font-size-base * 0.85)); // 13px


//== Links
//

@link-color: @riba-blue;
@link-hover-color: @riba-blue;
@link-hover-decoration: none;


//== Grid system
//

//maximum width of the site
@max_width: 100%;
