// Organism: "Join RIBA Eligibility"
//
// Provides key information about a membership eligibility

//== Variables
//

//== Mixins
//

//== Block
//
.join-riba-eligibility  {
    padding: 0 1.25em 1.5em 1.25em;

    background: @riba-light-stone-blue;

    @media (min-width: @screen-md-min) {
        padding-bottom: 2em;
    }

    p {
        font-size: 1.125em;

        @media (min-width: @screen-md-min) {
            font-size: 1.375em;
        }
    }
}

//== Elements
//
.join-riba-eligibility__title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.563em;

    @media (min-width: @screen-sm-min) {
        margin-bottom: 0;

        font-size: 2.188em;
    }
}

.join-riba-eligibility__intro {
    text-align: center;

    @media (min-width: @screen-sm-min) {
        margin-top: .25em;
    }
}

.join-riba-eligibility__criteria {
    margin: 2em 0 1em 0;

    @media (min-width: @screen-sm-min) {
        margin: .75em 0 0 0;
    }
}

.join-riba-eligibility__location {
    display: block;
    width: 100%;
    padding-bottom: .5em;
    border-bottom: 1px solid @riba-slate;

    font-size: 1.25em;
    font-weight: bold;
    line-height: 1.45;
    text-transform: uppercase;

    @media(min-width: @screen-sm-min) {
        font-size: 1.625em;
    }
}

.join-riba-eligibility__requirement {
    margin-bottom: 0;
    font-size: 1.25em;

    @media (min-width: @screen-sm-min) {
        font-size: 1.625em;
    }
}

.join-riba-eligibility__location-sub {
    padding-left: 1.5em;

    font-size: .8em;
    font-weight: normal;
    text-transform: none;
    color: @riba-cool-grey;

    @media (min-width: @screen-sm-min) {
        font-size: .615em;
    }
}

//== Modifiers
//
.join-riba-eligibility__criteria--last {
    margin-top: 3.75em;
}

.join-riba-eligibility__requirement--standalone {
    margin: 1.6em 0;
}

.join-riba-eligibility__requirement--last {
    margin-bottom: 0;
}

//== Defaults
//
