@import '../themes/default/colours.less';
@import '../themes/default/config.less';
@import '../mixins/link.less';
@import '../mixins/content-container.less';

// Organism: "Footer"
//
// The main RIBA site footer

//== Block
//

.footer {
    background-color: @riba-slate;
    color: @riba-white;

    padding: 2.625em 0 1em;
}

//== Elements
//
.footer__container {
    .content-container();
}

.footer__navigation {
    @media (min-width: @screen-lg-min) {
        float: right;
        margin-bottom: 1em;
    }
}

.footer__logos {
    margin-bottom: 1em;

    @media (min-width: @screen-lg-min) {
        position: absolute;
        top: 0;
        bottom: 0;

        .flexbox & {
            display: flex;
            flex-direction: column;
        }
    }
}

//== Modifiers
//

.footer__container--top {
    min-height: 15em;
}
