//
// Styles for divider atom
// =========================================

//== Mixins
//

//== Block
//

.divider {
    width: 100%;
    height: 1px;

    background-color: @riba-cool-grey;
}

//== Elements
//

//== Modifiers
//

.divider--thick {
    height: 3px;

    background-color: @riba-slate;
}

.divider--dark {
    background-color:  @riba-slate;
}

.divider--light {
    background-color: @riba-stone-blue;
}
