//
// Image Link
// =========================================

//== Mixins
//

//== Block
//

.image-link {
    display: inline-block;
    margin-right: 1.25em;
    margin-bottom: 1.25em;
}

//== Elements
//

//== Modifiers
//
