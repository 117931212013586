//
// Styles for file-upload atom
// =========================================

//== Mixins
//

//== Block
//

.file-upload {
    width: 100%;
}

//== Elements
//

.file-upload__list {
    margin-bottom: 16px;
    background-color: @info-secondary;
}

.file-upload__button {
    width: 100%;
    padding: 16px;
    margin-bottom: 16px;
}

.file-upload__input,
.file-upload__inputList {
    opacity: 0;
    height: 0;
    width: 0;
}

//== Modifiers
//

.file-upload__list--hidden {
    .file-upload__list();
    display: none;
}

