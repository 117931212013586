// Molecule: "Event Buy Ticket Molecule"
//

//== Variables
//

//== Mixins
//

//== Block
//

//== Elements
//
.event-buy-ticket__button {
    width: 100%;
    min-height: 2.5em;

    @media (min-width: @screen-md-min) {
        width: auto;
        min-width: 15.625em;
    }
}

.event-buy-ticket__content-container {
    padding: 0;
}

//== Modifiers
//
.event-buy-ticket__button--opaque {
    background: @riba-light-stone-blue;
    border-color: @riba-light-stone-blue;
    color: @riba-slate;

    &:hover,
    &:focus,
    &:active {
        color: @riba-slate;
    }
}

//== Defaults
//
