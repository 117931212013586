// Molecule: "List Item"

//== Block
//

.list-item {
    padding: 13px 16px;
    border-bottom: 2px solid white;

    background: @riba-light-grey;

    .clearfix();

    @media (min-width: @screen-md-min) {
        padding: 20px 20px 20px 0;
        border-bottom: 1px solid @riba-light-stone-blue;

        background: white;
    }
}


//== Elements
//

.list-item__link {
    display: block;

    color: @riba-slate;

    .clearfix();

    @media (min-width: @screen-md-min) {
        float: left;
        width: 70%;
        margin-right: 5%;
    }

    @media (min-width: @screen-lg-min) {
        width: 75%;
        margin-right: 5%;
    }

    @media (min-width: 1440px) {
        margin-right: 7%;
    }
}

.list-item__image {
    float: left;

    width: 100px;
    margin-right: 10px;

    vertical-align: top;

    @media (min-width: @screen-sm-min) {
        width: 150px;
    }

    @media (min-width: @screen-md-min) {
        width: 250px;
    }

    @media (min-width: @screen-lg-min) {
        width: 350px;
        margin-right: 20px;
    }

    .list-item__icon {
        position: relative;
        left: 50%;

        margin-top: 10px;

        transform: translateX(-50%);
        font-size: 50px;
        color: @riba-stone-blue;

        @media (min-width: @screen-sm-min) {
            font-size: 100px;
            margin-top: 15px;
        }
    }
}

.list-item__main {
    margin-bottom: .5em;

    overflow: hidden;

    @media (min-width: @screen-sm-min) {
        margin-bottom: 0;
    }
}

.list-item__label,
.list-item__sublabel {
    display: block;
    float: left;

    margin-bottom: 5px;

    color: @riba-green;

    font-weight: bold;
    font-size: .75em;
    line-height: 1em;

    text-transform: uppercase;
    letter-spacing: 2.7px;

    @media (min-width: @screen-sm-min) {
        margin-bottom: 11px;
    }
}

.list-item__sublabel {
    clear: left;
    color: @riba-cool-grey;

    @media (min-width: 340px) {
        padding-left: 1em;
        margin-left: 1em;

        border-left: 1px solid @riba-cool-grey;

        clear: none;
    }
}

.list-item__title {
    margin:  0 0 .375em;

    color: @riba-slate;

    font-size: 1em;
    line-height: 1.25em;

    text-transform: uppercase;
    clear: both;

    @media (min-width: @screen-sm-min) {
        margin-bottom: .55em;

        font-size: 1.625em;
    }
}

.list-item__synopsis {
    color: @riba-slate;

    font-size: .875em;
    font-weight: normal;
    line-height: 1.286em;

    @media (min-width: @screen-sm-min) {
        font-size: 1.125em;
    }

    @media (min-width: @screen-md-min) {
        min-height: 3em;
    }

    @media (min-width: @screen-lg-min) {
        min-height: 6.75em;
    }
}

.list-item__subject {
    display: block;
    float: left;

    margin-top: 1em;

    font-size: .75em;
    font-weight: normal;
    color: @riba-cool-grey;

    @media (min-width: @screen-sm-min) {
        font-size: 1em;
    }

    & + & {
        position: relative;
        padding-left: .75em;
        margin-left: .75em;

        &:before {
            position: absolute;
            left: -2.5px;
            top: 50%;

            transform: translateY(-50%);

            height: 5px;
            width: 5px;
            border-radius: 50%;

            background: @riba-cool-grey;
            content: '';
        }
    }
}

.list-item__details {
    padding-left: 108px;

    @media (min-width: @screen-sm-min) {
        padding-left: 158px;
    }

    @media (min-width: @screen-md-min) {
        padding: 1.75em 0 0;
        overflow: hidden;
    }

    @media (min-width: @screen-lg-min) {
        min-height: 11.95em;
    }

    @media (min-width: 1440px) {
        text-align: left;
    }
}

.list-item__detail {
    position: relative;
    display: block;

    padding-left: 2.053em;
    margin-bottom: .3em;

    font-size: .75em;
    font-weight: normal;

    line-height: 1.563em;

    color: @riba-slate;

    .clearfix();

    @media (min-width: @screen-sm-min) {
        font-size: 1em;
    }
}

.list-item__icon {
    position: absolute;
    left: 0;
    top: 3px;

    color: @riba-cool-grey;
    font-size: 1.25em;
}

.list-item__button {
    display: none;

    @media (min-width: @screen-md-min) {
        display: inline-block;
        width: 12em;
    }

    @media (min-width: 1300px) {
        width: 12.5em;
    }

    @media (min-width: 1400px) {
        width: 13.125em;
    }

    @media (min-width: 1600px) {
        width: 14.625em;
    }
}

//== Modifiers
//

.list-item__icon--prize {
    margin-left: 2px;
}

.list-item__label--competition {
    color: @riba-taupe;
}

.list-item__label--event {
    color: @riba-medium-pink;
}
