//
// Styles for tooltip
// =========================================

//== Mixins
//

//== Block
//

.tooltip {
    position: absolute;
    padding: 5px 14px;
    max-width: 350px;
    min-width: 350px;
    z-index: 1000;
    top: 0;
    left: 0;
    visibility: hidden;

    cursor: default;
    color: @riba-slate;
    background-color: @info-primary;
    border: solid 1px @info-secondary;

    font-size: 14px;

    transition: opacity .3s ease;
    opacity: 0;

    @media (max-width: @screen-sm-min) {
        min-width: 0;
    }
}

//== Elements
//

.tooltip__title {
    font-weight: bold;
    margin-bottom: 7px;
}

.tooltip__close {
    position: absolute;
    top: 7px;
    right: 7px;

    cursor: pointer;

    .material-icons {
        font-size: 14px;
        color: @riba-slate;
    }
}

//== Modifiers
//

.tooltip--visible {
    opacity: 1;
    visibility: visible;
}
