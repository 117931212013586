@import '../themes/default/colours.less';
@import '../themes/default/config.less';

// Molecule: "Footer Navigation"
//
// The navigation in the RIBA site footer

//== Variables
//

//== Mixins
//

//== Block
//

.footer-navigation {
    .clearfix();

    display: block;

    background-color: @riba-slate;
    color: @riba-white;

    .flexbox & {
        display: flex;
        flex-wrap: wrap;
    }
}

//== Elements
//

.footer-navigation__list {
    float: left;

    width: 100%;
    margin-right: 0;
    margin-bottom: 2em;

    ul {
        margin: 0;
        padding: 0;

        list-style-type: none;
    }

    @media (min-width: @screen-xs-min) {
        width: 25%;
    }

    @media (min-width: @screen-md-min) {
        width: auto;
        margin-right: 2.5em;
        margin-bottom: 1em;
    }

    .flexbox & {
        flex: 1 1 49%; // Safari bug
        width: 50%;

        @media (min-width: @screen-xs-min) {
            flex: 1 1 24%; // Safari bug
            width: 25%;
        }

        @media (min-width: @screen-md-min) {
            flex: 1 1 auto;
            width: auto;
        }
    }
}

.footer-navigation__link {
    font-weight: bold;
    font-size: .875em;
    color: @riba-stone-blue;

    &:hover, &:active, &:focus, &:visited {
        color: @riba-stone-blue;
        text-decoration: none;
    }
}

//== Modifiers
//

.footer-navigation__link--title {
    display: block;
    padding-bottom: .625em;

    font-size: 1em;
    color: @riba-white;

    &:hover, &:active, &:focus, &:visited {
        color: @riba-white;
        text-decoration: none;
    }
}

//== Defaults
//
