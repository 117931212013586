@import '../mixins/adaptive.less';

//== Mixins
//

//== Block
//
.primary-nav {
    width: 100%;
    text-align: right;
}

//== Elements
//
.primary-nav__content {
    display: flex;
    flex-wrap: wrap;

    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;

    text-align: left;

    background: @riba-light-stone-blue;

    border-bottom: 1px solid @riba-black;

    &.js-toggle-hidden {
        display: none;
    }

    @media (min-width: @screen-md-max) {
        border: none;

        display: block;
        position: relative;

        background: transparent;

        &.js-toggle-hidden {
            display: block;
        }
    }
}

.primary-nav__icon {
    display: inline-block;
    padding: .5em;

    cursor: pointer;

    @media (min-width: @screen-md-max) {
        display: none;
    }
}

.primary-nav__items {
    margin: 0;
    padding: 0;

    @media(max-width: @screen-md-max) {
        width: 100%;
    }

    @media (min-width: @screen-md-max) {
        float: right;
    }
}

.primary-nav__cta {
    flex: 1 0 auto;
    width: 50%;
    padding: 10px;
    float: none;

    @media (min-width: @screen-md-max) {
        float: right;
        width: auto;
        padding: 18px 0 18px 10px;
    }

    @media (min-width: 1440px) {
        padding: 25px 0 25px 10px;
    }
}

.primary-nav__cta-button {
    @media (min-width: @screen-md-min) {
        width: auto;
    }

    @media (max-width: @screen-lg-min) {
        width: 100%;
    }
}

//== Modifiers
//
.primary-nav__icon--menu,
.primary-nav__icon--close,
.primary-nav__icon--my-account {
    padding-right: 0;
}

.primary-nav__icon--menu,
.primary-nav__icon--close,
.primary-nav__icon--my-account {
    &.js-toggle-hidden {
        display: none;
    }
}

.primary-nav__icon--my-account {
    .primary-nav__icon--search-lg();

    > span {
        color: @riba-slate;
    }

    .hideOn(min; @screen-lg-min);
}

.primary-nav__icon--search-lg {
    display: none;

    @media (min-width: @screen-lg-min) {
        display: block;
        float: right;

        padding: 24px 4px 20px;
    }

    @media (min-width: 1440px) {
        padding-top: 32px;
        padding-bottom: 24px;
    }
}

.primary-nav__cta--show-medium {
    display: none;

    @media (min-width: @screen-md-max) and (max-width: 1439px) {
        display: block;
    }
}

.primary-nav__cta--hide-medium {
    @media (min-width: @screen-md-max) and (max-width: 1439px) {
        display: none;
    }
}

.primary-nav__cta--secondary {
    order: 1;
}

.primary-nav__cta--primary {
    order: 2;
}

//== Defaults
//
