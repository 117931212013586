// Organism: "Join RIBA How It Works"
//
// Provides information on the RIBA membership process

//== Variables
//

//== Mixins
//

//== Block
//
.join-riba-how-it-works   {
    padding: 0 1.25em;

    background: @riba-slate;
    text-align: center;

    @media (min-width: @screen-md-min) {
        .clearfix();
    }
}

//== Elements
//
.join-riba-how-it-works__title {
    margin-top: 1em;

    color: @riba-white;
    font-size: 1.563em;
    text-transform: uppercase;

    @media (min-width: @screen-md-min) {
        margin-bottom: 1.8em;

        font-size: 2.188em;
    }
}

.join-riba-how-it-works__step {
    text-align: center;

    @media (min-width: @screen-md-min) {
        display: inline-block;
        vertical-align: top;
        width: 20%;
    }
}

.join-riba-how-it-works__step-icon {
    display: block;
    padding-bottom: .25em;

    font-size: 5em;
    color: @riba-light-stone-blue;
}

.join-riba-how-it-works__step-description {
    display: block;
    max-width: 14em;
    margin: 0 auto;

    color: @riba-white;
    font-size: 1em;

    @media (min-width: @screen-md-min) {
        max-width: none;

        font-size: 1.125em;
    }
}

.join-riba-how-it-works__step-divider-container {
    @media (min-width: @screen-md-min) {
        display: inline-block;
        vertical-align: top;
        width: 5%;
    }
}

.join-riba-how-it-works__step-divider {
    display: block;
    margin: .5em 0 .25em 0;

    font-size: 3em;
    color: @riba-grey;

    @media (min-width: @screen-md-min) {
        transform: rotate(270deg);
    }
}

.join-riba-how-it-works__button {
    width: auto;
    padding-left: 1em;
    padding-right: 1em;
    margin: 2.75em 0 1.875em;
    border: 2px solid @riba-stone-blue;

    @media (min-width: @screen-md-min) {
        margin: 2.75em 0 3.25em;
    }
}

//== Modifiers
//

//== Defaults
//
