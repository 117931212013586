//
// Styles for file-upload-item atom
// =========================================

//== Mixins
//

//== Block
//

.file-upload-item {
    vertical-align: middle;
    padding: 5px 0;
    text-align: left;
}

//== Elements
//

.file-upload-item__size {
    display: inline-block;
    width: 60px;
    vertical-align: middle;
    padding-left: 6px;

    color: @riba-cool-grey;

    font-size: 12px;
    text-align: left;
}

.file-upload-item__icon {
    display: inline-block;

    vertical-align: middle;
    padding-left: 10px;

    text-align: center;

    .material-icons {
        font-size: 16px;
    }
}

.file-upload-item__name {
    display: inline-block;

    width: 212px;
    vertical-align: middle;
    padding-left: 16px;

    color: @riba-slate;

    text-align: left;
    font-weight: bold;
    font-size: 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-upload-item__delete {
    .material-icons();

    font-size: 18px;
    color: @riba-blue;

    vertical-align: middle;
    cursor: pointer;
}

//== Modifiers
//

.file-upload-item:first-child {
    padding-top: 8px;
}

.file-upload-item:last-child {
    padding-bottom: 8px;
}
