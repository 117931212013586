//
// Styles for date-input atom
// =========================================

//== Mixins
//

//== Block
//

.date-input {
    .appearance(none);
    border-radius: 0;
    padding: 8px 50px 8px 20px;
    line-height: 1.35;
    border: 1px solid @riba-stone-blue;
    width: 12em;

    color: @riba-slate;
    background: none;

    @media(max-width: @screen-sm-min) {
        width: 100%;
    }
}

//== Elements
//

//== Modifiers
//

.date-input--error {
    border: 1px solid @error-primary;

    outline: none;
}

.date-input--show-picker {
    .date-input();

    background: url('/images/date-input/ic_event_gray_24px.svg') no-repeat;
    background-color: @riba-white;
    background-position-x: 90%;
    background-position-y: center;

    @media(max-width: @screen-sm-min) {
        background-position-x: 95%;
    }
}
