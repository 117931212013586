// Organism: "Join RIBA Category Hero"
//
// Provides key information about a membership category

//== Variables
//
@content-md-width: 25em;
@content-lg-width: 41em;
@content-md-background-position-x: 20em;
@content-lg-background-position-x: 30em;
@screen-xl-min: 1440px;

//== Mixins
//

//== Block
//

.join-riba-category-hero  {
    min-height: 28em;

    @media (max-width: @screen-sm-max) {
        background-image: none !important;
    }

    @media (min-width: @screen-md-min) {
        background-color: @riba-slate;
        background-position: @content-md-background-position-x center;
        background-repeat: no-repeat;
    }

    @media (min-width: @screen-lg-min) {
        background-position: @content-lg-background-position-x center;
    }

    @media (min-width: @screen-xl-min) {
        background-position: right center;
        background-size: 65%;
    }
}

//== Elements
//
.join-riba-category-hero__image {
    width: 100%;

    @media (min-width: @screen-md-min) {
        display: none;
    }
}

.join-riba-category-hero__large-content-container {
    padding-left: 0;
    padding-right: 0;
}

.join-riba-category-hero__content {
    padding: 1.4em 0 1.875em;

    background: @riba-slate;

    @media (min-width: @screen-md-min) {
        width: @content-md-width;
        min-height: 28em;
    }

    @media (min-width: @screen-lg-min) {
        width: @content-lg-width;
        padding-right: 1.25em;
    }
}

.join-riba-category-hero__title {
    margin-bottom: .7em;

    color: @riba-white;
    font-size: 1.875em;
    line-height: 1.17;
    text-transform: uppercase;

    @media (min-width: @screen-lg-min) {
        margin-top: .3em;
        margin-bottom: .5em;

        font-size: 2.813em;
    }
}

.join-riba-category-hero__intro {
    margin: 1em 0;

    color: @riba-white;
    line-height: 1.56;

    @media (min-width: @screen-lg-min) {
        font-size: 1.125em;
    }
}

.join-riba-category-hero__button {
    width: 100%;
    margin-top: 2em;
    margin-bottom: 0;

    background: @riba-light-stone-blue;
    border-color: @riba-light-stone-blue;
    color: @riba-slate;

    &:hover,
    &:focus,
    &:active {
        color: @riba-slate;
    }

    @media (min-width: @screen-md-min) {
        width: auto;
        padding-left: 2em;
        padding-right: 2em;
        margin-bottom: 2em;
    }
}

//== Modifiers
//
.join-riba-category-hero__intro--bold {
    font-weight: bold;
}

//== Defaults
//
