//
// Styles for form-value-container molecule
// =========================================

//== Mixins
//

//== Block
//

.form-value-container {
    margin: 16px auto;
}

//== Elements
//

.form-value-container__label {
    display: inline-block;
    vertical-align: middle;
    width: 40%;

    @media(max-width: @screen-sm-min) {
        width: 100%;
    }
}

.form-value-container__value {
    display: inline-block;
    vertical-align: middle;
    width: 59%;

    @media(max-width: @screen-sm-min) {
        width: 100%;
    }
}

//== Modifiers
//
