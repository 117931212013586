//
// Links
// =========================================

//== Mixins
//

//== Block

.data-table {

    td {
        vertical-align: middle !important;
    }

    .button--small {
        margin: 0 0.625em;
    }


}

//

//== Elements

.data-table__primary-data {
    font-size: 1.3em;
}

.data-table__secondary-data {
    color: #999999;
    font-size: 0.85em;
}

.data-table__secondary-data__icon {
    font-size: 1.5em;
    position: relative;
    top: 0.2em;
}

.data-table__action-column {
    text-align: center;

}

//

//== Modifiers
//

//== Defaults
//
