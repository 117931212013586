// Adaptive mixin for showing & hiding elements based on screen

.hideOn(min; @min-width) {
     @media(min-width: @min-width) {
         display: none;
     }
}

.hideOn(max; @max-width) {
    @media (max-width: @max-width) {
        display: none;
    }
}

.showOn(min; @min-width) {
    @media(min-width: @min-width) {
        display: initial;
    }
}

.showOn(max; @max-width) {
    @media (max-width: @max-width) {
        display: initial;
    }
}
