//
// Sitecore errors
// =========================================

.input-validation-error {
    border: 1px solid @error-primary !important;

    outline: none;
}

.field-validation-error {
    display: block;

    color: @error-primary;
}

.validation-summary-errors {

}
