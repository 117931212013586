//
// Styles for resource-header atom
// =========================================

//== Mixins
//

//== Block
//

.resource-header {
    display: flex;
    flex-wrap: nowrap;
}

//== Elements
//

.resource-header__icon {
    .material-icons();

    flex: 0 0 100px;
    font-size: 100px;

    vertical-align: middle;

    color: @riba-stone-blue;

    @media (max-width: @screen-xs-max) {
        flex: 0 0 50px;
        font-size: 50px;
    }
}

.resource-header__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
}

.resource-header__subtitle {
    font-size: 12px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: @riba-green;
    margin: 0;
}

.resource-header__title {
    font-size: 45px;
    line-height: 52px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
    color: @riba-slate;
    margin: 0;

    @media (max-width: @screen-xs-max) {
        font-size: 30px;
        line-height: 35px;
    }
}

//== Modifiers
//
