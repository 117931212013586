//
// Styles for available-resources molecule
// =========================================

//== Mixins
//

//== Block
//

.available-resources {
    width: 100%;

    margin-bottom: 40px;
    padding: 35px 0;

    background-color: @info-secondary;
    text-align: center;

    @media (max-width: @screen-sm-max) {
        margin-bottom: 20px;
        padding: 26px 0 0 0;
    }
}

//== Elements
//

.available-resources__title {
    text-transform: uppercase;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    margin: 0;

    @media (max-width: @screen-sm-max) {
        font-size: 26px;
        line-height: 30px;
    }
}

.available-resource__resource-list {
    display: inline-block;

    list-style: none;
    text-align: left;

    margin: 0;
    padding: 24px 0 0;

    @media (max-width: @screen-sm-max) {
        padding: 16px 8px;
    }
}

.available-resource__resource {
    font-size: 16px;
    line-height: 25px;
    padding-bottom: 15px;

    > * {
        vertical-align: middle;
        line-height: 25px;
    }
}

.available-resource__resource-icon {
    .material-icons();

    width: 16px;
    font-size: 16px;
    line-height: 25px;
}

.available-resource__resource-title {
    padding-left: 8px;
}

.available-resource__resource-file-info {
    padding-left: 8px;
    font-size: 12px;
    color: @riba-cool-grey;
}

//== Modifiers
//
